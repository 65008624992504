import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ga4Event } from '../../../../../../shared/utils/ga4Event';
import { Button } from 'hosman-material';
import { S_CircleCheck } from 'hosman-material';

const SponsorshipCard = () => {
  const { user } = useSelector(state => state.user);

  return (
    <a href='https://share.hosman.co/?utm_source=site_hosman&utm_medium=successpage&utm_campaign=parrainage'
       target='_blank'
       onClick={() => ga4Event('click_parrainage_home', { user_id: user.id })}
    >
      <div className='flex flex-col gap-8 text-ih-indigo border-2 border-ih-purple-20 hover:border-ih-purple-40 bg-hosman-gradient-1 rounded-3xl px-4 py-10 md:px-7 md:py-14' data-testid='SponsorshipCard'>
        <div className='text-xl font-bold text-ih-indigo text-center'>
          <p>Envie de gagner jusqu'à 600€ ?</p>
          <p>Parrainez un proche !</p>
        </div>

        <div className='flex flex-wrap gap-3 justify-center'>
          <div className='flex flex-col items-center gap-3 border border-ih-blue-70 rounded-2xl bg-white p-3'>
            <p className='font-medium'>Mandat de vente</p>

            <div className='flex items-center gap-2'>
              <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-blue' />
              <p className='text-sm'>200€ offerts</p>
            </div>
          </div>

          <div className='flex flex-col items-center gap-3 border border-ih-blue-70 rounded-2xl bg-white p-3'>
            <p className='font-medium'>Vente conclue</p>

            <div className='flex items-center gap-2'>
              <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-blue' />
              <p className='text-sm'>400€ offerts</p>
            </div>
          </div>
        </div>

        <div className='md:w-max mx-auto'>
          <Button label="Découvrez l'offre de parrainage" />
        </div>
      </div>
    </a>
  );
}

export default SponsorshipCard;
