import client from '../../client';

const PROPERTIES = {
  getProperty(id) {
    return client.get(`/sellers/${client.customerId()}/properties/${id}`);
  },

  updateProperty(id, params) {
    return client.put(`/sellers/${client.customerId()}/properties/${id}`, { property: params });
  },
}

export default PROPERTIES;
