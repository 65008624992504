import React from 'react';

import { Label } from 'hosman-material';

const Criterias = ({ data }) => {
  const { zone, apartment, house, min_area, min_room_number, min_bedroom_number } = data;

  return (
    <div className='flex flex-wrap justify-center gap-2'>
      {zone.map(city => {
        return (
          <Label color='bg-ih-indigo' key={city}>{city.replace(', France', '')}</Label>
        )
      })}

      {apartment && <Label color='bg-ih-indigo'>Appartement</Label>}
      {house && <Label color='bg-ih-indigo'>Maison</Label>}
      {min_area && <Label color='bg-ih-indigo'>{min_area} m²</Label>}
      {min_room_number && <Label color='bg-ih-indigo'>{min_room_number}p</Label>}
      {min_bedroom_number && <Label color='bg-ih-indigo'>{min_bedroom_number}c</Label>}
    </div>
  );
};

export default Criterias;
