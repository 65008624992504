import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { D_CalendarStar } from 'hosman-material';

import PageTitle from '../../../../../../components/PageTitle/PageTitle';
import SaleCard from './components/SaleCard';
import NoSalesInterestsCard from './components/NoSalesInterestsCard';

import { LoadingLayer, FadeIn } from 'hosman-material';

const InterestsIndex = () => {
  const { salesInterests, saleInterestsState } = useSelector((state) => state.buyerSalesInterests);
  const { buyerAlerts, buyerAlertsState } = useSelector((state) => state.buyerBuyerAlerts);
  let navigate = useNavigate();

  // If user has no salesInterests, navigate to seller app
  // If user has only one saleInterest, navigate to InterestShow
  useEffect(() => {
    if (saleInterestsState !== 'done' || buyerAlertsState !== 'done') return;

    if (salesInterests.length === 0 && buyerAlerts.length === 0) navigate('/ev');
    if (salesInterests.length === 1) navigate(`${salesInterests[0].id}`);
  }, [saleInterestsState, buyerAlertsState, saleInterestsState, buyerAlerts]);

  return (
    <div data-testid='InterestsIndex'>
      <PageTitle icon={D_CalendarStar} title='Visites et offres' />

      <LoadingLayer isReady={saleInterestsState == 'done'}>
        <FadeIn>
          {salesInterests.length == 0 && <NoSalesInterestsCard />}

          {salesInterests.length > 0 &&
            <>
              <p className='h2 text-ih-indigo text-center mb-10'>Voici les biens qui vous intéressent</p>

              <div className='flex flex-col md:flex-row gap-6 md:flex-wrap'>
                {salesInterests.map((sale) => (
                  <Link to={`${sale.id}`} key={sale.id} className='relative cursor-pointer rounded-3xl mb-6'>
                    <SaleCard sale={sale} />
                  </Link>
                ))}
              </div>
            </>
          }
        </FadeIn>
      </LoadingLayer>
    </div>
  )
}

export default InterestsIndex;
