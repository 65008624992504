import client from '../client';

const BUYER_ALERTS = {
  createBuyerAlert(params) {
    return client.post('/buyer_alerts', {
      user: {
        ...params.user,
        buyer_alerts_attributes: [params.buyer_alert_attributes]
      }
    });
  },

  validateBuyerAlert(params) {
    return client.post('/buyer_alerts/validations', { buyer_alert: params })
  },

  similarProperties(params) {
    return client.post('/buyer_alerts/properties', { buyer_alert_params: params })
  }
}

export default BUYER_ALERTS;
