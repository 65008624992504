import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FadeIn, Checkbox } from 'hosman-material';

import { updateStep, updateEstimationData } from '../../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const NonSellingReasonSelector = () => {
  const { estimationData, estimationData: { waitingTimePeriod, nonSellingReason }} = useSelector((state) => state.estimationFunnel);
  const dispatch = useDispatch();

  const reasons = [
    { slug: 'family_activity', name: "J'ai besoin d'une estimation pour une donation, une succession, ou un divorce" },
    { slug: 'administration', name: "J'ai besoin d'une estimation pour renégocier mon prêt ou pour les impôts" },
    { slug: 'buying', name: "J'achète ce bien et souhaite vérifier sa valeur" },
    { slug: 'tenant', name: 'Je suis locataire de ce bien' },
    { slug: 'curiosity', name: "Je souhaite vérifier la valeur de mon bien" }
  ]

  useEffect(() => {
    if (waitingTimePeriod !== 'no_selling_project') return;

    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }, 220);
  }, [waitingTimePeriod]);

  const handleChange = (reason) => {
    dispatch(updateEstimationData({ ...estimationData, nonSellingReason: reason.slug }))
    dispatch(updateStep({ stepNumber: 6 }))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (waitingTimePeriod !== 'no_selling_project') return null;
  return (
    <FadeIn>
      <div className='flex flex-col gap-4' data-testid='NonSellingReasonSelector'>
        <p className='h3 text-ih-indigo'>Pourquoi estimez-vous ce bien ?</p>

        <div className='border border-ih-purple-20 rounded-xl'>
          {reasons.map(reason => {
            return (
              <div
                key={reason.slug}
                className='p-4 bg-white hover:bg-ih-purple-5 border-b border-ih-purple-20 last:border-b-0 first:rounded-t-xl last:rounded-b-xl cursor-pointer'
                onClick={() => handleChange(reason)}
              >
                <Checkbox
                  key={reason.slug}
                  name={reason.slug}
                  label={reason.name}
                  isChecked={reason.slug === nonSellingReason}
                  readOnly
                />
              </div>
            )
          })}
        </div>
      </div>
    </FadeIn>
  )
}

export default NonSellingReasonSelector;
