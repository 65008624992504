import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { starStruck } from '../../../../../../../../../../shared/assets/emojis';
import { buyerAlertCreatorLeftPanelStep2 } from '../../../../../../../../../../shared/assets/images';

import { S_CircleCheck, Emoji } from 'hosman-material';

const Step2 = () => {
  return (
    <div className='flex flex-col items-center justify-center gap-14'>
      <div className='flex flex-col items-center justify-center gap-6'>
        <div className='h2 flex flex-col justify-center items-center gap-2 text-center'>
          <Emoji emojiSrc={starStruck} width='w-11' />

          <p>Un bien vous a <span className='text-ih-blue'>séduit ?</span></p>
        </div>

        <div className='flex flex-col items-center justify-center gap-2 font-medium'>
          <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green w-5' />

            <p>Réservez votre visite en un clic</p>
          </div>

          <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green w-5' />

            <p>Convaincu ? Remettez une offre en ligne !</p>
          </div>
        </div>
      </div>

      <img src={buyerAlertCreatorLeftPanelStep2} alt='alerte achat' />
    </div>
  )
}

export default Step2;
