import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InfoIcon, Avatar, Box } from 'hosman-material';
import { S_Phone, S_Envelope } from 'hosman-material';

export const ContactCard = ({ imageSrc, title, firstName, phoneNumber, email, civility }) => {
  const tooltipContent = () => {
    if (title == 'Expert Hosman') {
      return (
        <>
          <p className='font-semibold'>Expert Hosman ({firstName})</p>
          <p>Votre conseiller après la visite, {civility == 'mrs' ? 'elle' : 'il'} chapeaute la vente et les négociations.</p>
        </>
      )
    }

    if (title == 'Guide de visite') {
      return (
        <>
          <p className='font-semibold'>Guide de visite ({firstName})</p>
          <p>Expert du quartier et du bien, en charge des visites</p>
        </>
      )
    }
  }

  return (
    <div className='bg-ih-purple-5 w-full md:w-fit px-2 py-5 h-full flex flex-col md:flex-row items-center gap-3 rounded-2xl text-center md:text-left' data-testid='ContactCard'>
      <Avatar imageSrc={imageSrc} border={true} />

      <div className='flex flex-col gap-2'>
        <div className='flex flex-col md:flex-row items-center gap-2'>
          <p className='text-sm'>{title}</p>
          <InfoIcon tooltip={tooltipContent()} bgColor='bg-ih-purple-30' />
        </div>

        <p className='text-sm flex flex-col md:flex-row gap-1'>
          <span className='font-semibold'>{firstName}</span>
          <span>({phoneNumber.replace(/.{2}/g, '$& ').trim()})</span>
        </p>
      </div>

      <div className='flex'>
        <a href={`tel:${phoneNumber}`} className='mr-3'>
          <Box bgColor='bg-ih-purple-20 hover:bg-ih-purple-30'>
            <FontAwesomeIcon icon={S_Phone} data-testid='icon-box' className='text-ih-indigo' />
          </Box>
        </a>

        {email &&
          <a href={`mailto:${email}`}>
            <Box bgColor='bg-ih-blue-20 hover:bg-ih-blue-30'>
              <FontAwesomeIcon icon={S_Envelope} data-testid='icon-box' className='text-ih-blue' />
            </Box>
          </a>
        }
      </div>
    </div>
  );
};

ContactCard.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  email: PropTypes.string,
  civility: PropTypes.string
};
