import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import SALES from '../../api/ressources/SellerApp/sales';

export const getSales = createAsyncThunk(
  '/sales',
  async (params) => {
    const response = await SALES.getSales(params)
    return response.data
  }
);

export const getSale = createAsyncThunk(
  '/sale',
  async (saleId) => {
    const response = await SALES.getSale(saleId)
    return response.data
  }
);

export const getPublicationData = createAsyncThunk(
  '/publicationData',
  async (saleId) => {
    const response = await SALES.getPublicationData(saleId)
    return response.data
  }
);

const initialState = {
  sales: [],
  sale: {},
  publicationData: {},
  propertySelectOptions: [],
  requestState: '',
  showRequestState: ''
};

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    resetSale(state) {
      state.sale = initialState.sale;
    },
    resetPublicationData(state) {
      state.publicationData = initialState.publicationData;
    },
    setPropertySelectOptions(state) {
      state.propertySelectOptions = formPropertySelectOptions(JSON.parse(JSON.stringify(state)));
    },
    updateSale: (state, action) => {
      state.sale = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSales.pending, (state) => {
      state.sales = initialState.sales;
      state.requestState = 'pending'
    })
    builder.addCase(getSales.fulfilled, (state, { payload }) => {
      state.sales = payload.results.map(sale => ({
        ...sale,
        mandateSigned: ['awaiting_publication', 'awaiting_offer', 'awaiting_sale_agreement', 'awaiting_notarial_deed', 'sold'].includes(sale.state)
      }));
      state.requestState = 'done'
    })
    builder.addCase(getSale.pending, (state) => {
      state.sale = initialState.sale;
      state.showRequestState = 'pending'
    })
    builder.addCase(getSale.fulfilled, (state, { payload }) => {
      state.sale = {
        ...payload,
        mandateSigned: ['awaiting_publication', 'awaiting_offer', 'awaiting_sale_agreement', 'awaiting_notarial_deed', 'sold'].includes(payload.state),
        availiableForVisits: ['awaiting_publication', 'awaiting_offer'].includes(payload.state) && (payload.flatsy_id && payload.flatsy_state === 'available' || ['expert', 'hosman_guide'].includes(payload.visits_management))
      };
      state.showRequestState = 'done'
    })
    builder.addCase(getPublicationData.pending, (state) => {
      state.publicationData = initialState.publicationData;
    })
    builder.addCase(getPublicationData.fulfilled, (state, { payload }) => {
      state.publicationData = payload;
    })
  },
})

const formPropertySelectOptions = (state) => {
  if (state.sale.id) {
    return (state.sales.map((mapSale) => {
      return {
        id: mapSale.id,
        saleId: mapSale.id,
        address: `${mapSale.property.street_number} ${mapSale.property.street_name} ${mapSale.property.zip_code} ${mapSale.property.city}`,
      };
    }).filter(function (obj) {
      return obj.saleId !== state.sale.id;
    }))
  }
};

export const { resetSale, resetPublicationData, setPropertySelectOptions, updateSale } = salesSlice.actions;

export default salesSlice.reducer;
