import React from 'react';
import { useSelector } from 'react-redux';

import { leboncoinLogo,
         jinkaLogo,
         selogerLogo,
         bellesDemeures,
         luxResidence,
         proprietesFigaro } from '../../../../../../../../../../assets/logos';
import { MarketingCard } from './shared/MarketingCard';

const PortalsContacts = () => {
  const { sale } = useSelector((state) => state.sellerSales);

  const handleContactPortailsSubtitle = () => {
    if (sale.on_ubiflow) return "Publié sur tous les sites d'annonces immobilières"
    else return "Votre bien n'est actuellement pas publié sur les sites immobiliers"
  }

  const handleContactPortailsLogos = () => {
    if (!sale.on_ubiflow) return []

    if (sale.premium) return [
      { src: bellesDemeures, altText: 'bellesDemeures'},
      { src: luxResidence, altText: 'luxResidence' },
      { src: proprietesFigaro, altText: 'proprietesFigaro' }
    ]

    return [
      { src: leboncoinLogo, altText: 'leboncoin' },
      { src: jinkaLogo, altText: 'jinka' },
      { src: selogerLogo, altText: 'seloger' }
    ]
  }

  return(
    <MarketingCard
      number={sale.on_ubiflow ? '40+' : '0'}
      title={`Sites immobiliers`}
      subtitle={handleContactPortailsSubtitle()}
      logos={handleContactPortailsLogos()}
    />
  )
};

export default PortalsContacts
