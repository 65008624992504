import React from 'react';

import { SelectInput } from 'hosman-material';

const options = [
  { id: 1, value: 'already_offering', name: 'Je veux acheter dès que possible (recherche urgente)' },
  { id: 2, value: 'already_visiting', name: "Je veux acheter d'ici 3 mois" },
  { id: 3, value: 'starting_visits', name: "Je veux acheter d'ici 6 mois" },
  { id: 4, value: 'not_urgent', name: 'Je ne suis pas pressé(e) mais je me renseigne' },
];

const ProjectStateSelect = ({ value, onChange, errors }) => {
  return (
    <div className='w-full'>
      <SelectInput
        name='project_state'
        options={options}
        selectedOption={options.find((option) => option.value === value) || null}
        handleChange={onChange}
        label="Où en êtes vous dans votre projet d'achat ?"
        errors={errors}
      />
    </div>
  );
};

export default ProjectStateSelect;
