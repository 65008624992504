import React from 'react';
import PropTypes from 'prop-types';

import { Card, Button, PriceBox, TimeLineOfferCard } from 'hosman-material';

const StateCard = ({ offer, handleShowOffer, handleNewOffer }) => {
  const updatedAt = () => {
    switch (offer.review_status) {
      case 'accepted':
      case 'dropped':
        return offer.timeline.review_accepted_at
      case 'refused':
        return offer.timeline.review_refused_at
    }
  }

  return (
    <Card>
      <div className='flex flex-col md:flex-row items-center px-5 py-6 min-w-[280px]'>
        <div className='w-full md:w-fit border-b md:border-b-0 border-ih-purple-30 mb-5 md:mb-0'>
          <TimeLineOfferCard reviewStatus={offer.review_status} createdAt={offer.created_at} updatedAt={updatedAt()} />
        </div>

        <div className='flex items-center flex-wrap flex-col md:flex-row gap-y-5 gap-x-3 md:gap-x-0 md:gap-y-0 w-full md:w-fit'>
          <div className='md:w-fit pl-0 md:pl-5 md:pr-10 md:border-l border-ih-purple-30 justify-self-center order-1'>
            {offer.review_status && <PriceBox title='Votre offre' price={offer.amount.toLocaleString()} />}
          </div>

          {offer.review_status && (
            <div className='md:pl-0 md:pr-3 text-center w-full md:w-fit order-3 md:order-2'>
              <Button onClick={handleShowOffer} label="Voir l'offre" type={`${offer.review_status === 'accepted' ? 'primary' : 'tertiary'}`} small />
            </div>
          )}

          {(offer.review_status !== 'accepted' && offer.review_status !== 'dropped') && (
            <div className='md:pl-0 md:pr-3 text-center w-full md:w-fit order-2 md:order-3'>
              <Button onClick={handleNewOffer} label={`${offer.review_status === null ? 'Finaliser mon offre' : 'Faire une contre-offre'}`} small />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default StateCard;

// PropTypes
StateCard.propTypes = {
  offer: PropTypes.object.isRequired,
  handleShowOffer: PropTypes.func.isRequired,
  handleNewOffer: PropTypes.func.isRequired
};
