import React from "react";
import { SelectInput } from 'hosman-material';

const elevatorOptions = [
  { id: 0, value: 0, name: 'Pas nécessairement' },
  { id: 1, value: 1, name: 'Si 1er étage ou plus' },
  { id: 2, value: 2, name: 'Si 2e étage ou plus' },
  { id: 3, value: 3, name: 'Si 3e étage ou plus' },
  { id: 4, value: 4, name: 'Si 4e étage ou plus' },
  { id: 5, value: 5, name: 'Si 5e étage ou plus' },
]

const ElevatorSelect = ({ buyerAlert, setStateBuyerAlert, errors }) => {
  return (
    <SelectInput
      name='min_floor_for_elevator'
      options={elevatorOptions}
      selectedOption={elevatorOptions.find((option) => option.value === buyerAlert.min_floor_for_elevator) || elevatorOptions[0]}
      handleChange={(e) => setStateBuyerAlert({...buyerAlert, min_floor_for_elevator: e.value})}
      label={<p>Ascenseur <span className='text-xs font-normal'>(appartement uniquement)</span></p>}
      errors={errors}
    />
  )
}

export default ElevatorSelect;
