import React from 'react';
import PropTypes from 'prop-types';

export const AddToCalendarLinks = ({ visit }) => {
  if (visit.calendar_links.length < 1) return null;

  return (
    <div className='flex flex-wrap gap-1 items-center' data-testid='AddToCalendarLinks'>
      <p className='hidden md:block font-semibold'> - </p>
      <p className='font-semibold'>Ajouter à </p>
      {visit.calendar_links.map((calendarLink, index) => {
        return (
          <div key={index} className='flex gap-1 items-center'>
            {index !== 0 && <p>-</p>}
            <a href={calendarLink.link} target='_blank' className='text-ih-blue underline font-semibold'>{calendarLink.label.charAt(0).toUpperCase() + calendarLink.label.slice(1)}</a>
          </div>
        )
      })}
    </div>
  );
};

AddToCalendarLinks.propTypes = {
  visit: PropTypes.object
};
