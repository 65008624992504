import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { generateLink } from '../../../../../../../shared/utils/railsRoutes';
import { shortDateAndYear } from '../../../../../../../shared/utils/formattedDates';

import { Card, Button } from 'hosman-material';

const LastOffer = ({ offer, offers }) => {
  const { salesInterests } = useSelector((state) => state.buyerSalesInterests);
  const sale = salesInterests.find(sale => sale.id === offer?.sale_id)

  if (!sale) return null;

  const textContent = () => {
    switch (offer.review_status) {
      case null:
      case 'pending':
      case 'ongoing':
      case 'partly_accepted':
      case 'unsent_for_review':
        if (offer.status === 'completed') {
          return `Votre offre de ${offer.amount.toLocaleString()} € a bien été remise le ${shortDateAndYear(offer.created_at)}`
        } else {
          return `Votre offre de ${offer.amount.toLocaleString()} € a a été commencée le ${shortDateAndYear(offer.created_at)}`
        }
      case 'accepted':
        return `Félicitations, votre offre de ${offer.amount.toLocaleString()} € a été acceptée le ${shortDateAndYear(offer.timeline.review_accepted_at)}`;
      case 'refused':
      case 'dropped':
        return `Votre offre de ${offer.amount.toLocaleString()} € a été refusée le ${shortDateAndYear(offer.timeline.review_refused_at || offer.timeline.review_dropped_at)}` ;
    }
  }

  return (
    <div className='flex flex-col gap-3' data-testid='LastOffer'>
      <div className='flex items-center gap-3'>
        <p className='h3 text-ih-indigo'>Votre dernière offre</p>
        {offers.length > 1 && <Link to={salesInterests.length > 1 ? '/ea/interests' : `/ea/interests/${sale.id}/mes-offres`} className='text-ih-blue underline'>+{offers.length-1} autre{offers.length-1 > 1 ? 's' : ''}</Link>}
      </div>

      <Card classes='flex flex-col md:flex-row justify-between items-center gap-3 p-4 border-ih-purple-20'>
        <div className='flex flex-col md:flex-row items-center gap-5'>
          <img className='w-full md:w-20 aspect-[3/1] md:aspect-[1/1] object-cover rounded-2xl' src={sale.property.property_pictures[0].photo_url} />

          <div className='flex flex-col justify-between gap-3'>
            <div className='flex flex-col gap-4'>
              <p className='text-center md:text-left font-semibold'>{textContent()}</p>

              <div className='flex flex-wrap justify-center md:justify-start items-center gap-2'>
                <p className='text-ih-indigo-40'>{sale.property.address}</p>
                <a href={sale.property.google_maps_link} target='_blank' className='text-ih-blue underline font-semibold'>Visualiser l'adresse</a>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-between items-center gap-3'>
          <Link to={`/ea/interests/${sale.id}/mes-offres`}>
            <Button label='Visualiser mon offre' />
          </Link>
          <Link to={generateLink(RailsRoutes.adverts_property_path(sale.property.slug))} target='_blank'>
            <Button label="Voir l'annonce" type='tertiary' />
          </Link>
        </div>
      </Card>
    </div>
  )
};

export default LastOffer;
