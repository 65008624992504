import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CircleCheck, S_CircleXmark } from 'hosman-material';

const Criterias = ({ buyerAlert }) => {
  const displayCriteria = (display, check, label) => {
    if (!display) return;

    return (
      <div className='flex items-center gap-2'>
        <FontAwesomeIcon icon={check ? S_CircleCheck : S_CircleXmark} className={check ? 'text-ih-green' : 'text-ih-red'} size='lg' />
        <p>{label}</p>
      </div>
    )
  }

  return (
    <div className='col-span-3 flex flex-col gap-5 py-3 md:px-3' data-testid='Criterias'>
      <p className='text-ih-indigo-60'>Critères de recherche</p>
      <div className='flex flex-wrap gap-4'>
        {displayCriteria(buyerAlert.apartment, true, 'Appartement')}
        {displayCriteria(buyerAlert.house, true, 'Maison')}
        {displayCriteria(buyerAlert.parking, true, 'Parking')}
        {displayCriteria(true, true, `> ${Number(buyerAlert.min_area)} m²`)}
        {displayCriteria(true, true, `${buyerAlert.min_room_number} pièce${buyerAlert.min_room_number > 1 ? 's' : ''}`)}
        {displayCriteria(true, true, `${buyerAlert.min_bedroom_number} chambre${buyerAlert.min_bedroom_number > 1 ? 's' : ''}`)}
        {displayCriteria(true, true, `Étage : ${buyerAlert.floor_text}`)}
        {displayCriteria(buyerAlert.renovation, true, 'Travaux potentiels')}
        {displayCriteria(!buyerAlert.renovation, false, 'Pas de travaux')}
        {displayCriteria(buyerAlert.min_floor_for_elevator > 0, true, `Ascenseur : ${buyerAlert.min_floor_for_elevator}e étage ou +`)}
        {displayCriteria(true, true, `Espace extérieur : ${buyerAlert.outdoor_space_text}`)}
      </div>
    </div>
  )
}

export default Criterias;
