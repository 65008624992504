import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { clappingHands } from '../../../../../shared/assets/emojis';
import { FadeIn, Avatar, Emoji, InfoIcon } from 'hosman-material';

const OfferSign = ({ offer, setStepInfos }) => {
  const { user, loggedUserId } = useSelector((state) => state.user);
  const [yousignUrl, setYousignUrl] = useState(null)
  let navigate = useNavigate();

  useEffect(() => {
    if (!offer.yousign_url) return;
    // disable_domain_validation=true will be ignored in production.
    setYousignUrl(`${offer.yousign_url}&disable_domain_validation=true`);
  }, [offer]);

  useEffect(() => {
    if (offer.review_status !== 'ongoing') {
      navigate(`/traitement-offre/${offer.id}`);
      return;
    }
    setStepInfos({ title: "Signature de l'offre", step: 2, total: 2 })
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    }
  }, []);

  const handleMessage = (e) => {
    if (e.origin === 'https://yousign.app' && e.data.type === 'yousign') {
      if (e.data.event === 'success') navigate(`/ev/ventes/${offer.sale.id}/offres?accepted=true&offer_id=${offer.id}`);
      if (e.data.event === 'error')  navigate(`/ev/ventes/${offer.sale.id}/offres`);
    }
  }

  return (
    <div data-testid='OfferSign'>
      <FadeIn>
        <div className='md:w-1/2 mx-auto px-7'>
          {offer.sale.expert &&
            <div className='flex gap-3'>
              <div className='w-max'>
                <Avatar border imageSrc={offer.sale.expert.user.profile_pic_url} />
              </div>

              <div>
                <div className='flex gap-2'>
                  <p className='h4 text-ih-neutral'>{offer.sale.expert.user.first_name}</p>
                  <p className='text-ih-blue'>{offer.sale.expert.user.phone_number.replace(/.{2}/g, '$& ')}</p>
                </div>
                <p className='subtitle text-ih-neutral-50'>Félicitations {user.first_name}, plus que quelques clics pour
                accepter l'offre de {offer.owner.first_name} {offer.owner.last_name}, d'un montant de {offer.amount.toLocaleString()} € !
                <span className='pl-3'><Emoji emojiSrc={clappingHands} width='w-6' /></span></p>
              </div>
            </div>
          }

          <div className='flex gap-3 mt-6'>
            <InfoIcon />
            <p className='text-ih-neutral'>Relisez avec attention le document avant de passer à la signature. Signez celui-ci en cliquant sur le bouton "Signer" au bas du document.</p>
          </div>
        </div>

      </FadeIn>

      {user.id == loggedUserId &&
        <iframe src={yousignUrl} className='w-full md:w-2/3 mx-auto mt-10 h-[800px]' referrerPolicy='strict-origin-when-cross-origin'></iframe>
      }

      {user.id != loggedUserId &&
        <p className='mt-10 text-ih-orange text-center'>Page de signature d'une offre accessible uniquement au vendeur principal</p>
      }
    </div>
  )
}

// PropTypes
OfferSign.propTypes = {
  offer: PropTypes.object.isRequired,
  setStepInfos: PropTypes.func.isRequired
};

export default OfferSign;
