import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ga4Event} from '../../../../../../../../../../shared/utils/ga4Event'
import { generateLink } from '../../../../../../../../../../shared/utils/railsRoutes';
import { greeting } from '../../../../../../../../../../shared/utils/helpers/hosmanVars';

import { TextInput, Button, Label, Modal } from 'hosman-material';
import { S_CircleCheck, S_EyeSlash, S_Eye } from 'hosman-material';

import USERS from '../../../../../../../../../../shared/apiPublic/ressources/users'
import { updateOpenModal, updateStoreUser } from '../../../../../../../../../reducers/_Pages/Public/buyerAlertCreatorSlice';
import { getUser } from '../../../../../../../../../reducers/App/userSlice';

const SignInSignUpModal = () => {
  const { openModal, storeUser } = useSelector((state) => state.buyerAlertCreator);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showPasswords, setShowPasswords] = useState({})
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const minCharPassword = storeUser.password?.length >= 8
  const newUser = storeUser.last_sign_in_at == null

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    dispatch(updateStoreUser({ ...storeUser, [name]: value }))
  }

  const validatePasswords = () => {
    const { password, passwordConfirmation } = storeUser;

    if (!minCharPassword) return setErrors({ password: ['*Le mot de passe doit contenir au minimum 8 caractères'] })
    if (password !== passwordConfirmation) return setErrors({ passwordConfirmation: ['*Les mots de passe ne sont pas identiques'] })

    return true;
  }

  const signUserIn = async() => {
    const passwordIsValid = !newUser || validatePasswords()

    if (!passwordIsValid) return;

    let response;
    try {
      setIsSubmitted(true)
      response = await USERS.signIn({ email: storeUser.email, password: storeUser.password })

      if (response.data.error) {
        setIsSubmitted(false)
        setErrors({ password: [`*${response.data.error}`] })
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (response.data.sign_in) {
        localStorage.setItem('token', response.data.token);
        dispatch(getUser());
        window.location = '/ea/alertes-achat';
      }
    }
  }

  const handleCloseModal = () => {
    ga4Event('click_guest_buyer_alert')
    navigate('success')
  }

  return (
    <Modal isOpen={openModal} closeModal={handleCloseModal} width='md:w-2/5'>
      <div className='flex flex-col items-center justify-center gap-5 p-5 text-ih-indigo'>
        <p className='h2'>Votre alerte a bien été créée</p>

        {newUser && <p className='font-medium text-center'>Créez votre espace Hosman pour visualiser votre alerte et accéder aux biens correspondant à vos critères.</p>}
        {!newUser && <p className='font-medium text-center'>{greeting(storeUser)}, on se connaît déjà ! Connectez-vous à votre espace Hosman pour visualiser votre alerte et accéder aux biens correspondant à vos critères.</p>}

        <div className='flex flex-col gap-4 w-full'>
          <div className='relative w-full'>
            <TextInput
              label='E-mail'
              name='email'
              value={storeUser.email}
              disabled={true}
            />

            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green absolute top-12 mt-1 right-3' />
          </div>

          <div className='flex flex-col gap-2 relative w-full'>
            <TextInput
              type={showPasswords.password ? 'text' : 'password'}
              label={newUser ? 'Créez votre mot de passe' : 'Mot de passe'}
              placeholder='Mot de passe'
              name='password'
              value={storeUser.password}
              onChange={handleChange}
              errors={errors}
            />

            <FontAwesomeIcon icon={showPasswords.password ? S_EyeSlash : S_Eye} className='text-ih-purple-50 absolute top-12 mt-1 right-3 cursor-pointer' onClick={() => setShowPasswords({ ...showPasswords, password: !showPasswords.password })} />
            <Label color={`w-max ${minCharPassword ? 'bg-ih-green-20' : 'bg-ih-blue-20'}`} textColor={minCharPassword ? 'text-ih-green' : 'text-ih-indigo'}>8 caractères minimum</Label>
          </div>

          {newUser &&
            <div className='relative w-full'>
              <TextInput
                type={showPasswords.newPassword ? 'text' : 'password'}
                label='Confirmer votre mot de passe'
                placeholder='Mot de passe'
                name='passwordConfirmation'
                value={storeUser.passwordConfirmation}
                onChange={handleChange}
                errors={errors}
              />

              <FontAwesomeIcon icon={showPasswords.newPassword ? S_EyeSlash : S_Eye} className='text-ih-purple-50 absolute top-12 mt-1 right-3 cursor-pointer' onClick={() => setShowPasswords({ ...showPasswords, newPassword: !showPasswords.newPassword })} />
            </div>
          }

          <div className='flex justify-center'>
            <Button onClick={() => signUserIn()} label='Accéder à mon espace acheteur' isLoading={isSubmitted} />
          </div>

          {!newUser &&
            <a href={generateLink(RailsRoutes.new_user_password_path())} className='text-center text-ih-indigo font-semibold'>
              Mot de passe oublié?
            </a>
          }
        </div>
      </div>
    </Modal>
  );
};

export default SignInSignUpModal;
