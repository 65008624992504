import React from 'react';
import PropTypes from 'prop-types';

import { Label, Emoji } from 'hosman-material';

const StateLabel = ({ visit }) => {
  const labelInfos = () => {
    switch (visit.state) {
      case 'proposed':
      case 'confirmed':
        return { color: 'bg-ih-blue', label: 'Visite à venir' };
      case 'done':
        return { color: 'bg-ih-green', label: 'Visite effectuée' };
      case 'rescheduled':
        return { color: 'bg-ih-orange', label: 'Visite reportée' };
      case 'cancelled_visitor':
      case 'cancelled_owner':
      case 'cancelled_guide':
      case 'cancelled_terminated':
        return { color: 'bg-ih-red', label: 'Visite annulée' };
    }
  }

  const payload = labelInfos();

  return (
    <Label color={payload.color}>{payload.label}</Label>
  )
}

export default StateLabel;

// PropTypes
StateLabel.propTypes = {
  visit: PropTypes.object.isRequired
};
