import React, { useEffect } from 'react';
import { RadioInput } from 'hosman-material';

const options = [
  { name: 'Oui', value: true },
  { name: 'Non pas du tout', value: false }
]

const RenovationRadio = ({ buyerAlert, setStateBuyerAlert, errors }) => {
  const handleChange = (value) => {
    setStateBuyerAlert({ ...buyerAlert, renovation: value })
  }

  return (
    <RadioInput
      name='renovation'
      options={options}
      value={buyerAlert.renovation ?? null}
      onChange={handleChange}
      label='Travaux potentiels'
      small
      errors={errors}
    />
  )
}

export default RenovationRadio;
