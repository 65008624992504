import React, { useState, useEffect } from 'react';

import PropertyCard from './components/PropertyCard';
import LinkToAdverts from './components/LinkToAdverts';
import { Separator, Button, Slider, LoadingCircle } from 'hosman-material';

import BUYER_ALERTS from '../../../../../../../../api/ressources/App/buyerAlerts';

const CorrespondingProperties = ({ buyerAlert, firstAlert }) => {
  const [state, setState] = useState('closed');
  const [properties, setProperties] = useState([]);
  const [total, setTotal] = useState(null);

  const fetchCorrespondingProperties = async () => {
    setState('loading')
    const response = await BUYER_ALERTS.getCorrespondingProperties(buyerAlert.id)
    setProperties(response.data.results)
    setTotal(response.data.total)
    setState('open')
  }

  useEffect(() => {
    if (!firstAlert) return;

    setTimeout(() => {
      fetchCorrespondingProperties();
    }, 1000);
  }, []);

  useEffect(() => {
    if (total === null) return;

    fetchCorrespondingProperties();
  }, [buyerAlert]);

  const propertyItems = () => {
    const array = [...properties.map(property => <PropertyCard property={property} /> )]
    if (properties.length < total) array.push(<LinkToAdverts buyerAlert={buyerAlert} properties={properties} total={total} />)

    return array;
  }

  if (!buyerAlert.active) return null;

  return (
    <div className='text-center'>
      <Separator margins='mb-6' borderColor='border-ih-indigo-10' />

      {state === 'closed' && <Button type='text-blue' label='Voir les biens correspondants' onClick={() => fetchCorrespondingProperties()} />}
      {state === 'loading' &&
        <div className='w-min mx-auto'>
          <LoadingCircle />
        </div>
      }

      {state === 'open' &&
        <div className='flex flex-col gap-3'>
          <p className='h3 text-ih-indigo'>{total} bien{total > 1 ? 's' : ''} correspond{total > 1 ? 'ent' : ''} à vos critères de recherche</p>
          <Slider
            items={propertyItems()}
          />
        </div>
      }
    </div>
  )
}

export default CorrespondingProperties;
