import React from 'react';

import Actions from './components/Actions';
import Localisation from './components/Localisation';
import Criterias from './components/Criterias';
import CorrespondingProperties from './components/CorrespondingProperties';

import { Label } from 'hosman-material';

const BuyerAlertCard = ({ buyerAlert, firstAlert }) => {
  return (
    <div className={`flex flex-col gap-6 bg-white rounded-2xl border p-8 ${buyerAlert.active ? 'border-ih-indigo-10' : 'border-ih-red'}`} data-testid='BuyerAlertCard'>
      {!buyerAlert.active && <Label color='bg-ih-red w-max mx-auto'>Cette alerte achat est désactivée</Label>}

      <div className='grid grid-cols-1 divide-y md:grid-cols-7 md:divide-x md:divide-y-0 text-sm'>
        <Localisation zones={buyerAlert.zone} />

        <Criterias buyerAlert={buyerAlert} />

        <div className='col-span-2 flex flex-col gap-5 py-3 md:px-3'>
          <p className='text-ih-indigo-60'>Budget</p>
          <Label color='bg-ih-indigo w-max'>{buyerAlert.min_budget.toLocaleString()} € - {buyerAlert.max_budget.toLocaleString()} €</Label>
        </div>
      </div>

      <CorrespondingProperties buyerAlert={buyerAlert} firstAlert={firstAlert} />

      <Actions buyerAlert={buyerAlert} />
    </div>
  )
}

export default BuyerAlertCard;
