import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { D_CircleExclamation } from 'hosman-material';

export const Error = ({ name, label = 'Ce champs', errors = {} }) => {
  if (!errors[name]) return null;

  const displayError = () => {
    if (errors[name][0].startsWith('*')) return errors[name][0].replace('*', '');

    return `${label.replace(':', '')} ${errors[name][0]}`
  }

  return (
    <div className='flex justify-start items-center gap-2 mt-2'>
      <FontAwesomeIcon icon={D_CircleExclamation} size='lg' className='text-ih-red' />

      <p className='text-ih-red-60 text-xs'>{displayError()}</p>
    </div>
  );
};

Error.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errors: PropTypes.object
}
