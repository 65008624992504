import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BUYER_ALERTS from '../../../../shared/apiPublic/ressources/buyer_alerts';

export const createBuyerAlert = createAsyncThunk(
  'buyerAlertCreator/createBuyerAlert',
  async ({ buyerAlertParams, user, loggedIn }, { rejectWithValue }) => {
    try {
      const response = await BUYER_ALERTS.createBuyerAlert({ buyer_alert_attributes: formatBuyerAlert(buyerAlertParams), user: user });
      return { data: response.data, loggedIn: loggedIn }
    } catch (error) {
      return rejectWithValue(error.response.data.error.message);
    }
  }
);

export const getSimilarPropertiesCount = createAsyncThunk(
  'buyerAlertCreator/getSimilarPropertiesCount',
  async (buyerAlert) => {
    const response = await BUYER_ALERTS.similarProperties(formatBuyerAlert(buyerAlert));
    return response.data.total
});

export const validateBuyerAlert = createAsyncThunk(
  'buyerAlertCreator/validateBuyerAlert',
  async (buyerAlert, { rejectWithValue }) => {
    try {
      await BUYER_ALERTS.validateBuyerAlert(formatBuyerAlert(buyerAlert));
      return true;
    } catch (error) {
      return rejectWithValue(error.response.data.error.message);
    }
  }
);

const formatBuyerAlert = (buyerAlert) => {
  return {
    ...buyerAlert,
    max_budget: formatToNumberType(buyerAlert.max_budget),
    min_budget: formatToNumberType(buyerAlert.min_budget),
    min_area: formatToNumberType(buyerAlert.min_area),
  };
}

const formatToNumberType = (value) => {
  if (typeof value === 'string') return Number(value.replace(/\s+/g, ""));
  return value;
}

const initialState = () => {
  const url = new URL(window.location.href)
  const maxBudget = url.searchParams.get('maxBudget') ? Number(url.searchParams.get('maxBudget')).toLocaleString() : null
  return {
    storeBuyerAlert: { zone: [], max_budget: maxBudget },
    storeUser: { phone_country_code: 'FR' },
    showContactDetailsForm: false,
    similarPropertiesCount: 0,
    isSubmitting: false,
    openModal: false,
    errors: {},
    createdBuyerAlertData: {}
  }
};

export const buyerAlertCreatorSlice = createSlice({
  name: 'buyerAlertCreator',
  initialState,
  reducers: {
    updateShowContactDetailsForm(state, { payload }) {
      state.showContactDetailsForm = payload;
    },
    updateStoreBuyerAlert(state, { payload }) {
      state.storeBuyerAlert = payload;
    },
    updateStoreUser(state, { payload }) {
      state.storeUser = payload;
    },
    updateErrors(state, { payload }) {
      state.errors = payload;
    },
    updateOpenModal(state, { payload }) {
      state.openModal = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSimilarPropertiesCount.fulfilled, (state, { payload }) => {
      state.similarPropertiesCount = payload;
    });
    builder.addCase(validateBuyerAlert.rejected, (state, { payload }) => {
      state.errors = payload;
    });
    builder.addCase(validateBuyerAlert.fulfilled, (state, { payload }) => {
      state.showContactDetailsForm = true
    });
    builder.addCase(createBuyerAlert.pending, (state, { payload }) => {
      state.isSubmitting = true;
    });
    builder.addCase(createBuyerAlert.rejected, (state, { payload }) => {
      state.isSubmitting = false;
      state.errors = payload;
    });
    builder.addCase(createBuyerAlert.fulfilled, (state, { payload }) => {
      state.isSubmitting = false;
      state.createdBuyerAlertData = payload.data
      if (payload.data.redirect_url) {
        window.location = payload.data.redirect_url
      } else if (payload.loggedIn) {
        window.location = '/ea/alertes-achat';
      } else {
        state.openModal = true;
        state.storeUser = { ...state.storeUser, last_sign_in_at: payload.data.user.last_sign_in_at };
      }
    });
  },
})

export const { updateShowContactDetailsForm, updateStoreBuyerAlert, updateStoreUser, updateErrors, updateOpenModal } = buyerAlertCreatorSlice.actions;

export default buyerAlertCreatorSlice.reducer;
