import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContactCard from './ContactCard';

import { D_CalendarStar } from 'hosman-material';
import { LoadingLayer, FadeIn, Box, Button } from 'hosman-material';

const CancelModalContent = ({ expert, setCancelModalOpen }) => {
  return (
    <LoadingLayer isReady={expert != undefined}>
      <FadeIn>
        <div className='flex flex-col items-center gap-6 p-6'>
          <Box><FontAwesomeIcon icon={D_CalendarStar} className='text-ih-blue' size='lg' /></Box>

          <p className='h3 text-center text-ih-indigo font semibold max-w-[500px]'>Veuillez contacter {expert.first_name}, l'expert{expert.civility == 'mrs' ? 'e' : ''} en charge de ce bien afin d'annuler la visite.</p>

          <ContactCard expert={expert} />

          <div className='flex justify-center gap-3'>
            <Button label='Annuler' type='tertiary' onClick={() => setCancelModalOpen(false)} />
            <a href={`tel:${expert.phone_number}`}>
              <Button label={`Contacter ${expert.first_name}`} />
            </a>
          </div>
        </div>
      </FadeIn>
    </LoadingLayer>
  )
}

export default CancelModalContent;
