import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import EstimationResult from '../../../../../shared/EstimationResult';
import Header from '../../components/Header';

import ESTIMATIONS from '../../../../../../../shared/apiPublic/ressources/estimations';

const Result = ({ baseEstimationData }) => {
  const [sale, setSale] = useState(baseEstimationData);
  const intervalIdRef = useRef(null);
  let { uniqueHash } = useParams();

  useEffect(() => {
    if (sale.expert) return;

    intervalIdRef.current = setInterval(async () => {
      getAllocatedExpert();
    }, 3000);

    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    };
  }, []);

  const getAllocatedExpert = async() => {
    const response = await ESTIMATIONS.getBaseEstimationData(uniqueHash);
    setSale(response.data);

    if (response.data.expert && intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }

  return (
    <>
      <Header />

      <div className='ih-container mt-20'>
        <EstimationResult sale={sale} />
      </div>
    </>
  )
}

export default Result;
