import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ga4Event } from '../../../../../../shared/utils/ga4Event';

import { S_CircleCheck } from 'hosman-material';
import { Button } from 'hosman-material';

const Sponsorship = ({ sale: { owner, potential_premium } }) => {

  const handleGa4Event = () => {
    const data = {
      potential_premium,
      user_id: owner.id,
      user_email: owner.email,
      user_first_name: owner.first_name,
      user_last_name: owner.last_name
    }
    ga4Event('click_parrainage_success_page', data)
  }

  return (
    <a href='https://share.hosman.co/?utm_source=site_hosman&utm_medium=successpage&utm_campaign=parrainage' target='_blank' onClick={() => handleGa4Event()}>
      <div className='flex flex-col gap-8 text-ih-indigo border-2 border-ih-purple-20 hover:border-ih-purple-40 bg-hosman-gradient-1 rounded-3xl p-5' data-testid='Sponsorship'>
        <div className='h2 text-ih-indigo text-center'>
          <p>Envie de gagner jusqu'à 600€ ?</p>
          <p>Parrainez un proche !</p>
        </div>

        <div className='flex flex-wrap gap-3 justify-center'>
          <div className='flex flex-col items-center gap-3 border border-ih-blue-70 rounded-2xl bg-white p-3'>
            <p className='font-medium'>Mandat de vente</p>

            <div className='flex items-center gap-2'>
              <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' />
              <p className='text-sm'>200€ offerts</p>
            </div>
          </div>

          <div className='flex flex-col items-center gap-3 border border-ih-blue-70 rounded-2xl bg-white p-3'>
            <p className='font-medium'>Vente conclue</p>

            <div className='flex items-center gap-2'>
              <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' />
              <p className='text-sm'>400€ offerts</p>
            </div>
          </div>
        </div>

        <div className='md:w-max mx-auto'>
          <Button label="Découvrez l'offre de parrainage" />
        </div>
      </div>
    </a>
  );
}

export default Sponsorship;
