import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bell } from '../../../../../shared/assets/emojis';

import { LoadingLayer, FadeIn, Button, Emoji } from 'hosman-material';
import { S_Bell, D_BellOn } from 'hosman-material';

import PageTitle from '../../../../components/PageTitle/PageTitle';
import BuyerAlertCard from './components/BuyerAlertCard';
import NoBuyerAlerts from './components/NoBuyerAlerts';
import UnsubscriptionModal from './modals/UnsubscriptionModal';
import CreateModal from './modals/CreateModal';
import EditModal from './modals/EditModal';

import { setNewModalOpen } from '../../../../reducers/BuyerApp/buyerAlertsSlice';

const BuyerAlerts = () => {
  const { buyerAlerts, buyerAlertsState } = useSelector((state) => state.buyerBuyerAlerts);
  const dispatch = useDispatch();

  return (
    <div data-testid='BuyerAlerts'>
      <PageTitle icon={D_BellOn} title='Mes alertes' />

      <LoadingLayer isReady={buyerAlertsState == 'done'}>
        <FadeIn>
          {buyerAlerts.length <= 0 && <NoBuyerAlerts />}

          {buyerAlerts.length > 0 &&
            <div className='flex flex-col gap-6'>
              <div className='flex flex-col md:flex-row-reverse justify-center items-center gap-3'>
                <Emoji emojiSrc={bell} />
                <p className='h2 text-ih-indigo'>Voici vos <span className='text-ih-blue'>alertes achats</span></p>
              </div>

              <div className='flex justify-center'>
                <Button label='Créer une nouvelle alerte achat' onClick={() => dispatch(setNewModalOpen(true))} icon={S_Bell} />
              </div>

              <div className='flex flex-col gap-10 max-w-full md:max-w-4xl mx-auto'>
                {buyerAlerts.map((buyerAlert, index) => (
                  <BuyerAlertCard buyerAlert={buyerAlert} firstAlert={index === 0} key={buyerAlert.id} />
                ))}
              </div>
            </div>
          }

          <UnsubscriptionModal />
          <CreateModal />
          <EditModal />
        </FadeIn>
      </LoadingLayer>
  </div>
  )
}

export default BuyerAlerts;
