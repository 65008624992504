import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Route,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import CustomRoutes from '../../../../shared/utils/customRoutes';

import Home from '../../BuyerApp/pages/Home';
import Interests from '../../BuyerApp/pages/Interests';
import BuyerAlerts from '../../BuyerApp/pages/BuyerAlerts';
import BuyerGuide from '../../BuyerApp/pages/BuyerGuide';
import Sponsorship from '../../BuyerApp/pages/Sponsorship';
import Sidebar from '../../../components/Sidebar/Sidebar';

import { D_House, D_CalendarStar, D_Book, D_BellOn, D_MessageHeart } from 'hosman-material';

import { getSalesInterests, resetSalesInterests } from '../../../reducers/BuyerApp/salesInterestsSlice';
import { getVisits, resetVisits } from '../../../reducers/BuyerApp/visitsSlice';
import { getOffers, resetOffers } from '../../../reducers/BuyerApp/offersSlice';
import { getBuyerAlerts, resetBuyerAlerts } from '../../../reducers/BuyerApp/buyerAlertsSlice';

const BASE_PATH = '/ea';
const navigation = [
  { name: 'Accueil', path: `${BASE_PATH}`, icon: D_House },
  { name: 'Visites et offres', path: `${BASE_PATH}/interests`, icon: D_CalendarStar },
  { name: 'Mes alertes', path: `${BASE_PATH}/alertes-achat`, icon: D_BellOn },
  { name: "Guide de l'acheteur", path: `${BASE_PATH}/guide-acheteur`, icon: D_Book },
  { name: 'Parrainez un proche', subtitle: "Gagnez jusqu'à 600€ !", path: `${BASE_PATH}/parrainage`, icon: D_MessageHeart, gtmEventParams: 'click_parrainage_menu' }
]

const BuyerApp = ({ clientSwitch, accountCard }) => {
  const { user } = useSelector((state) => state.user);
  const [selectedNav, setSelectedNav] = useState(navigation[0])
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const matchedPath = navigation.find((nav, index) => index !== 0 && location.pathname.includes(nav.path));
    setSelectedNav(matchedPath || navigation[0])
  }, [navigation, location.pathname]);

  const handleNavChange = (navigation) => {
    setSelectedNav(navigation);
    navigate(navigation.path);
  }

  useEffect(() => {
    dispatch(getSalesInterests());
    dispatch(getVisits());
    dispatch(getOffers());
    dispatch(getBuyerAlerts());

    return function cleanup() {
      dispatch(resetSalesInterests());
      dispatch(resetVisits());
      dispatch(resetOffers());
      dispatch(resetBuyerAlerts());
    };
  }, []);


  return (
    <div data-testid='BuyerApp'>
      <Sidebar
        navigation={navigation}
        selectedNav={selectedNav}
        handleSelect={handleNavChange}
        clientSwitch={clientSwitch}
        accountCard={accountCard}
      >
        <CustomRoutes>
          <Route index element={<Home />} />
          <Route path='/interests/*' element={<Interests />} />
          <Route path='/alertes-achat' element={<BuyerAlerts />} />
          <Route path='/guide-acheteur' element={<BuyerGuide />} />
          <Route path='/parrainage' element={<Sponsorship />} />
        </CustomRoutes>
      </Sidebar>
    </div>
  );
}

// PropTypes
BuyerApp.propTypes = {
  clientSwitch: PropTypes.func,
  accountCard: PropTypes.func
}

export default BuyerApp;
