import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import VISITS from '../../api/ressources/BuyerApp/visits';

export const getVisits = createAsyncThunk(
  '/getVisits',
  async (params) => {
    const response = await VISITS.getVisits(params)
    return response.data
  }
);

const initialState = {
  allVisits: [],
  visitsState: ''
};

export const visitsSlice = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    resetVisits(state) {
      state.allVisits = initialState.allVisits;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getVisits.pending, (state) => {
      state.visitsState = 'pending'
    })
    builder.addCase(getVisits.fulfilled, (state, { payload }) => {
      state.allVisits = payload.results;
      state.visitsState = 'done'
    })
  },
})

export const { resetVisits } = visitsSlice.actions;

export default visitsSlice.reducer;
