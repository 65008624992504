import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { propertyCardCarousel } from '../../../../../../shared/assets/images';
import { ga4Event } from '../../../../../../shared/utils/ga4Event';

import { Button } from 'hosman-material';

const BuyerAlertCard = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <Link to='/ea/alertes-achat' onClick={() => ga4Event('click_buyer_alert_home', { user_id: user.id })}>
      <div className='flex flex-col gap-3 text-ih-indigo border-2 border-ih-purple-20 hover:border-ih-purple-40 bg-hosman-gradient-1 rounded-3xl p-7' data-testid='BuyerAlertCard'>
        <p className='text-xl font-bold text-ih-indigo text-center w-72'>Ne passez pas à côté du bien de vos rêves !</p>

        <img src={propertyCardCarousel} alt='Buyer alert cards' className='mx-auto w-60' />

        <div className='md:w-max mx-auto'>
          <Button label='Créer mon alerte achat' />
        </div>
      </div>
    </Link>
  );
};

export default BuyerAlertCard;
