import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { Error } from './Error';
const classNames = require('classnames');

const roomOptions = [
  { id: 1, value: 1, name: '1' },
  { id: 2, value: 2, name: '2' },
  { id: 3, value: 3, name: '3' },
  { id: 4, value: 4, name: '4' },
  { id: 5, value: 5, name: '5' },
];

const RoomRadio = ({ buyerAlert, setStateBuyerAlert, errors }) => {
  return (
    <div className='flex flex-col gap-4'>
      <p className='font-semibold'>Pièces(s) minimum </p>

      <div className='flex flex-col'>
        <RadioGroup value={buyerAlert.min_room_number ?? null} onChange={(value) => setStateBuyerAlert({...buyerAlert, min_room_number: value})} data-testid='radioGroup'>
          <div className='flex gap-2'>
            {roomOptions.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option.value}
                className={({ checked }) =>
                  classNames('flex items-center justify-center cursor-pointer text-ih-indigo font-semibold p-3 rounded-2xl w-10 h-10',
                    { 'border border-ih-blue-30 hover:border-ih-blue-80 bg-white': !checked },
                    { 'border-2 border-ih-blue bg-ih-blue': checked },
                  )
                }
                data-testid={`radio-${option.value}`}
              >
                {({ checked }) => (
                  <RadioGroup.Label as='span' className={checked ? 'text-white' : 'text-ih-indigo'}>{option.name}</RadioGroup.Label>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        <Error name='min_room_number' errors={errors} />
      </div>
    </div>
  )
};

export default RoomRadio;
