import client from '../../client';

const SELLER_DOCUMENTS = {
  getDocuments(saleId) {
    return client.get(`/sellers/${client.customerId()}/sales/${saleId}/documents`);
  },

  uploadDocument(saleId, params) {
    return client.post(`/sellers/${client.customerId()}/sales/${saleId}/documents`, params);
  }
};

export default SELLER_DOCUMENTS;
