import React from 'react';
import { NumberInput } from 'hosman-material';

const AreaNumber = ({ buyerAlert, setStateBuyerAlert, errors }) => {
  return (
    <div className='flex-1'>
      <NumberInput
        name='min_area'
        value={buyerAlert.min_area}
        onChange={(e) => setStateBuyerAlert({...buyerAlert, min_area: e.target.value})}
        sign='m²'
        label='Surface minimum'
        placeholder='-'
        errors={errors}
      />
    </div>
  );
};

export default AreaNumber;
