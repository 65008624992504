import React from 'react';

import { Avatar } from 'hosman-material';
const regionalExperts = 'https://res.cloudinary.com/vesta-home/image/upload/v1718803589/assets/Napoleon/Images/EstimationResult/expert_local.webp'
const fairPrice = 'https://res.cloudinary.com/vesta-home/image/upload/v1718802508/assets/Napoleon/Images/EstimationResult/visuel_pricing_regions.webp'
const onlineSpace = 'https://res.cloudinary.com/vesta-home/image/upload/v1718802508/assets/Napoleon/Images/EstimationResult/espace_vendeur.webp'

const HosmanPros = ({ sale }) => {
  return (
    <div className='flex flex-col gap-6' data-testid='HosmanPros'>
      <p className='h2 text-ih-indigo text-center'>Une vente sereine avec Hosman</p>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-6 justify-between' data-testid='HosmanPros'>
        <div className='flex flex-col gap-6'>
          <img src={regionalExperts} alt='expert' data-testid='RegionalExpert' />

          <div className='flex flex-col gap-3 text-ih-indigo'>
            <p className='text-xl font-bold'>Un expert local, 100% dédié à votre vente</p>
            <p className='text-sm'>Un expert spécialiste de votre quartier prendra en charge votre vente de A à Z. Formé à la négociation, il vous aidera à optimiser votre net vendeur.</p>
          </div>
        </div>

        <div className='flex flex-col gap-6'>
          <img src={fairPrice} alt='expert' data-testid='FairPrice' />

          <div className='flex flex-col gap-3 text-ih-indigo'>
            <p className='text-xl font-bold'>Un prix juste, pour un service de qualité</p>
            <p className='text-sm'>Réalisez en moyenne 15 000€ d'économies sur la vente de votre bien grâce à nos prix justes, à partir de 5 900€.</p>
          </div>
        </div>

        <div className='flex flex-col gap-6'>
          <img src={onlineSpace} alt='expert' data-testid='OnlineSpace' />

          <div className='flex flex-col gap-3 text-ih-indigo'>
            <p className='text-xl font-bold'>De la transparence, grâce à votre espace en ligne</p>
            <p className='text-sm'>Suivez l'avancée de votre vente en temps réel sur votre espace client.</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default HosmanPros;
