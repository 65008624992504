import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import SaleFolder from '../../../../../../../components/Sale/SaleFolder';

import DOCUMENTS from '../../../../../../../api/ressources/BuyerApp/documents';

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  let { saleId } = useParams();

  const fetchDocuments = async () => {
    const response = await DOCUMENTS.getDocuments(saleId)
    setDocuments(response.data)
  }

  useEffect(() => {
    fetchDocuments()
  }, []);

  return <SaleFolder documents={documents} />
};

export default Documents;
