import React from 'react';
import PropTypes from 'prop-types';
import { generateLink } from '../../../../../../../../../../shared/utils/railsRoutes';

const LinkToAdverts = ({ buyerAlert, properties, total }) => {
  return (
    <a href={buyerAlert.annonces_url} target='_blank' className='w-60 h-full flex flex-col items-center justify-center border border-ih-purple-10 hover:border-ih-purple-30 rounded-2xl bg-hosman-gradient-1' data-testid='LinkToAdverts'>
      + {total - properties.length}
    </a>
  )
}

export default LinkToAdverts;

// PropTypes
LinkToAdverts.propTypes = {
  buyerAlert: PropTypes.object.isRequired,
  properties: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired
};
