import React, { useState, useEffect } from 'react';

import GeocoderZonesSelect from '../../../../shared/components/GeocoderZonesSelect';
import {
  PropertyKindCheckbox,
  BudgetNumber,
  AreaNumber,
  FloorSelect,
  ElevatorSelect,
  RenovationRadio,
  OutdoorSpaceSelect,
  FreeFieldTextArea,
  RoomRadio,
  BedroomRadio
} from './components/Inputs';

const Criterias = ({ buyerAlert, setStateBuyerAlert, errors }) => {
  const [zones, setZones] = useState(buyerAlert.zone || [])
  const commonProps = { buyerAlert, setStateBuyerAlert, errors };

  useEffect(() => {
    if (!buyerAlert.id) return;

    setZones(buyerAlert.zone)
  }, [buyerAlert]);

  const handleZonesChange = (zones) => {
    setZones(zones)
    setStateBuyerAlert({ ...buyerAlert, zone: zones })
  }

  return (
    <div data-testid='CriteriasForm'>
      <div className='flex flex-col gap-8 text-sm'>
        <GeocoderZonesSelect
          name='zone'
          label=''
          zones={zones}
          onChange={handleZonesChange}
          placeholder='Entrez une ville, un arrondissement...'
          listPlacement='top-10'
          errors={errors}
        />

        <PropertyKindCheckbox {...commonProps} />

        <div className='flex flex-col md:flex-row gap-5'>
          <BudgetNumber {...commonProps} />

          <AreaNumber {...commonProps} />
        </div>

        <div className='flex flex-col md:flex-row gap-8'>
          <RoomRadio {...commonProps} />

          <BedroomRadio {...commonProps} />
        </div>

        <div className='flex flex-col md:flex-row gap-5'>
          <FloorSelect {...commonProps} />

          <ElevatorSelect {...commonProps} />
        </div>

        <div className='flex flex-col md:flex-row gap-5'>
          <RenovationRadio {...commonProps} />

          <OutdoorSpaceSelect {...commonProps} />
        </div>

        <FreeFieldTextArea {...commonProps} />
      </div>
    </div>
  )
}

export default Criterias;
