import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NavigationButtons from '../../components/NavigationButtons';
import WaitingTimePeriodSelector from './components/WaitingTimePeriodSelector';
import NonSellingReasonSelector from './components/NonSellingReasonSelector';

import { FadeIn } from 'hosman-material';
import { D_CircleExclamation } from 'hosman-material';

const Reason = () => {
  const { errors } = useSelector((state) => state.estimationFunnel);
  // We need to reinitialize hasEffectRun to make sure it can rerun for a new estimation
  localStorage.removeItem('hasEffectRun');

  return (
    <div data-testid='Reason'>
      <FadeIn>
        <div className='flex flex-col gap-10'>
          <p className='h2 text-ih-indigo'>Votre projet de vente</p>

          <WaitingTimePeriodSelector />

          <NonSellingReasonSelector />
        </div>

        <div className='flex flex-col gap-6'>
          {errors.waitingTimePeriod &&
            <div className='flex justify-start items-center gap-2 mt-2'>
              <FontAwesomeIcon icon={D_CircleExclamation} size='lg' className='text-ih-red' />

              <p className='text-ih-red-60 text-xs'>{errors.waitingTimePeriod}</p>
            </div>
          }

          <NavigationButtons classes='hidden md:block my-5' />
        </div>
      </FadeIn>
    </div>
  )
}

export default Reason;
