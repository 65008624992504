import { configureStore } from '@reduxjs/toolkit';
import { errorsInterceptor } from '../shared/middlewares/errorsInterceptor';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import { userReducer, articlesReducer, partnersReducer } from './reducers/App/_index';
import { salesInterestsReducer, visitsReducer, offersReducer, buyerAlertsReducer } from './reducers/BuyerApp/_index';
import { sellerSalesReducer, sellerOffersReducer, sellerPhotoMeetingsReducer, sellerVisitsReducer, sellerUnavailabilitiesReducer, sellerDocumentsReducer, sellerEstimationFoldersReducer, sellerOnlineEstimationsReducer } from './reducers/SellerApp/_index';
import { estimationFunnelReducer, buyerAlertCreatorReducer } from './reducers/_Pages/_index';

// Redux persist config
const estimationFunnelPersistConfig = {
  key: 'estimationFunnel',
  storage,
  blacklist: ['allSteps', 'currentStep']
};

const persistedEstimationFunnelReducer = persistReducer(estimationFunnelPersistConfig, estimationFunnelReducer);

const reducers = {
  // Reducers only for General App
  user: userReducer,
  articles: articlesReducer,
  partners: partnersReducer,

  // Reducers only for BuyerApp
  buyerSalesInterests: salesInterestsReducer,
  buyerVisits: visitsReducer,
  buyerOffers: offersReducer,
  buyerBuyerAlerts: buyerAlertsReducer,


  // Reducers only for SellerApp
  sellerSales: sellerSalesReducer,
  sellerOffers: sellerOffersReducer,
  sellerPhotoMeetings: sellerPhotoMeetingsReducer,
  sellerVisits: sellerVisitsReducer,
  sellerUnavailabilities: sellerUnavailabilitiesReducer,
  sellerDocuments: sellerDocumentsReducer,
  sellerEstimationFolders: sellerEstimationFoldersReducer,
  sellerOnlineEstimations: sellerOnlineEstimationsReducer,

  // Reducers only for Public Pages
  estimationFunnel: persistedEstimationFunnelReducer,
  buyerAlertCreator: buyerAlertCreatorReducer
};

// Middlewares
let middlewares = [errorsInterceptor];

// Adds redux logger only in developement mode
if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}

export function setupStore(preloadedState) {
  return configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      immutableCheck: process.env.NODE !== 'test',
      serializableCheck: false
    }).concat(middlewares),
    reducer: reducers,
    preloadedState
  })
}
