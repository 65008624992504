import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bell } from '../../../../../../shared/assets/emojis';

import { Modal, Box, Emoji, SelectInput, Button } from 'hosman-material';

import { updateBuyerAlert, updateUnsubscriptionBuyerAlert } from '../../../../../reducers/BuyerApp/buyerAlertsSlice';

const reasons = [
  { id: 0, value: 'already_found', name: "J'ai trouvé le bien que je cherchais" },
  { id: 1, value: 'too_many_alerts', name: "Je reçois trop d'annonces" },
  { id: 2, value: 'alerts_not_relevant', name: "Les annonces que vous m'envoyez ne sont pas pertinentes par rapport à ma recherche" },
  { id: 3, value: 'no_longer_interested', name: 'Je ne cherche plus à acheter de bien immobilier' },
  { id: 4, value: 'other_reason', name: 'Autre raison' }
]

const UnsubscriptionModal = () => {
  const { isSubmitting, unsubscriptionBuyerAlert } = useSelector((state) => state.buyerBuyerAlerts);
  const [selectedReason, setSelectedReason] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setError(null);
    setSelectedReason(e);
  }

  const handleSubmit = () => {
    if (selectedReason === null) return setError('Veuillez sélectionner un motif');
    setSelectedReason(null);
    dispatch(updateBuyerAlert({ id: unsubscriptionBuyerAlert.id, params: { ...unsubscriptionBuyerAlert, active: false, unsubscription_reason: selectedReason.value, unsubscribed_at: new Date() }, action: 'subsciption' }))
  }

  return (
    <div data-testid='UnsubscriptionModal'>
      <Modal isOpen={unsubscriptionBuyerAlert !== null} closeModal={() => dispatch(updateUnsubscriptionBuyerAlert(null))} width='w-full md:w-2/3 h-auto'>
        <div className='flex flex-col gap-6 items-center p-6 md:w-96 mx-auto'>
          <Box>
            <Emoji emojiSrc={bell} width='w-6' />
          </Box>

          <p className='h3 text-center'>Vous êtes sur le point de vous <span className='text-ih-blue'>désinscrire</span> d'une alerte achat</p>

          <div className='flex flex-col gap-2 w-full mb-6'>
            <SelectInput
              name='unsubscription_reason'
              options={reasons}
              selectedOption={selectedReason}
              handleChange={(e) => handleChange(e)}
              placeholder='Choisir un motif'
              label={<p className='font-light'>Pour quelle raison souhaitez-vous vous désinscrire de cette alerte achat ?</p>}
              listHeight='h-full md:h-28'
            />

            {error && <p className='h-10 text-ih-red-60 text-xs'>{error}</p>}
          </div>

          <div className='flex gap-3'>
            <Button label='Annuler' type='tertiary' onClick={() => dispatch(updateUnsubscriptionBuyerAlert(null))} />
            <Button label='Me désinscrire' onClick={() => handleSubmit()} isLoading={isSubmitting} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UnsubscriptionModal;
