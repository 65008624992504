import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'hosman-material';

import Criterias from '../../../../../components/BuyerAlertForms/CriteriasForm';
import { setNewModalOpen, createBuyerAlert, updateError } from '../../../../../reducers/BuyerApp/buyerAlertsSlice';

const CreateModal = () => {
  const { newModalOpen, isSubmitting, errors } = useSelector((state) => state.buyerBuyerAlerts);
  const [stateBuyerAlert, setStateBuyerAlert] = useState({ zone: []});
  const dispatch = useDispatch();

  const formatBuyerAlert = () => {
    return {
      ...stateBuyerAlert,
      max_budget: formatToNumberType(stateBuyerAlert.max_budget),
      min_budget: formatToNumberType(stateBuyerAlert.min_budget),
      min_area: formatToNumberType(stateBuyerAlert.min_area),
    };
  }

  const formatToNumberType = (value) => {
    if (typeof value === 'string') return Number(value.replace(/\s+/g, ""));
    return value;
  }

  const handleSetStateBuyerAlert = (buyerAlert) => {
    setStateBuyerAlert(buyerAlert);

    Object.keys(buyerAlert).forEach((key) => {
      if (errors[key]) dispatch(updateError({ ...errors, [key]: null }));
    });
  }

  return (
    <Modal isOpen={newModalOpen} closeModal={() => dispatch(setNewModalOpen(false))}>
      <div className='flex flex-col gap-6 p-7 md:p-10 max-w-2xl' data-testid='CreateModal'>
        <p className='h2 font-bold text-ih-indigo text-center'>Création d'une alerte achat</p>

        <Criterias buyerAlert={stateBuyerAlert} setStateBuyerAlert={handleSetStateBuyerAlert} errors={errors} />

        <div className='flex justify-end'>
          <Button
            label='Enregistrer mes critères'
            onClick={() => dispatch(createBuyerAlert(formatBuyerAlert()))}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreateModal;
