import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bell } from '../../../../../../../../../../shared/assets/emojis';
import { buyerAlertCreatorLeftPanel } from '../../../../../../../../../../shared/assets/images';

import { S_CircleCheck, Emoji } from 'hosman-material';

const Step1 = () => {
  return (
    <div className='flex flex-col items-center justify-center gap-14'>
      <div className='flex flex-col items-center justify-center gap-6'>
        <div className='h2 w-2/3 flex flex-col justify-center items-center gap-2 text-center'>
          <Emoji emojiSrc={bell} width='w-11' />

          <p>Ne passez pas à côté du <span className='text-ih-blue'>bien de vos rêves !</span></p>
        </div>

        <div className='flex flex-col items-center justify-center gap-2 font-medium'>
          <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green w-5' />

            <p>Recevez par mail des annonces ultra-détaillées</p>
          </div>

          <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green w-5' />

            <p>Un accès avant toute diffusion publique</p>
          </div>
        </div>
      </div>

      <img src={buyerAlertCreatorLeftPanel} alt='alerte achat' />
    </div>
  )
}

export default Step1;
