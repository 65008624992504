import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ga4Event } from '../../../../../../../shared/utils/ga4Event';

import { Card, Box, Button } from 'hosman-material';
import { S_Check, D_BellOn } from 'hosman-material';

import { setEditedBuyerAlert } from '../../../../../../reducers/BuyerApp/buyerAlertsSlice';

const LastBuyerAlert = () => {
  const { user } = useSelector((state) => state.user);
  const { buyerAlerts } = useSelector((state) => state.buyerBuyerAlerts);
  const sortedBuyerAlerts = [...buyerAlerts]
  sortedBuyerAlerts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const buyerAlert = sortedBuyerAlerts[0] || null
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    ga4Event('click_refine_buyer_alert', { user_id: user.id });
    dispatch(setEditedBuyerAlert(buyerAlert));
    navigate('/ea/alertes-achat');
  }

  if (!buyerAlert) return null;
  return (
    <div className='flex flex-col gap-3' data-testid='LastBuyerAlert'>
      <div className='flex items-center gap-3'>
        <p className='h3 text-ih-indigo'>Votre dernière alerte achat</p>
        {buyerAlerts.length > 1 && <Link to={`/ea/alertes-achat`} className='text-ih-blue underline'>+{buyerAlerts.length-1} autre{buyerAlerts.length-1 > 1 ? 's' : ''}</Link>}
      </div>

      <Card classes='flex flex-col md:flex-row justify-between items-center gap-3 p-4 border-ih-purple-20'>
        <div className='flex flex-col md:flex-row items-center gap-5'>
          <Box><FontAwesomeIcon icon={D_BellOn} size='xl' className='text-ih-blue' /></Box>

          <div className='flex flex-col justify-between gap-3'>
            <div className='flex flex-col gap-2'>
              <div className='flex flex-wrap justify-center md:justify-start items-center gap-2'>
                <FontAwesomeIcon icon={S_Check} className='text-ih-green' />
                <p className='font-semibold'>Alerte achat créée avec succès</p>
              </div>

              <div className='flex flex-col md:flex-row flex-wrap gap-1 text-ih-indigo-40'>
                <p className='max-w-60 truncate'>{buyerAlert.zone.map(zone => zone.replace(', France', '')).join(', ')}</p>
                <p className='hidden md:block mx-2'>|</p><p>{buyerAlert.min_budget.toLocaleString()} à {buyerAlert.max_budget.toLocaleString()} €</p>
                <p className='hidden md:block mx-2'>|</p><p>{`> ${buyerAlert.min_area} m²`}</p>
                <p className='hidden md:block mx-2'>|</p><p>{`+ ${buyerAlert.min_room_number} pièces`}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-around'>
          <Button label='Affiner mon alerte achat' onClick={() => handleClick()} />
        </div>
      </Card>
    </div>
  )
};

export default LastBuyerAlert;
