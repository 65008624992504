import React from 'react';
import { NumberInput } from 'hosman-material';

const BudgetNumber = ({ buyerAlert, setStateBuyerAlert, errors }) => {
  return (
    <>
      <div className='flex-1'>
        <NumberInput
          name='min_budget'
          value={buyerAlert.min_budget}
          onChange={(e) => setStateBuyerAlert({...buyerAlert, min_budget: e.target.value})}
          sign='€'
          placeholder='-'
          label='Budget minimum'
          errors={errors}
        />
      </div>

      <div className='flex-1'>
        <NumberInput
          name='max_budget'
          value={buyerAlert.max_budget}
          onChange={(e) => setStateBuyerAlert({...buyerAlert, max_budget: e.target.value})}
          sign='€'
          placeholder='-'
          label='Budget maximum'
          errors={errors}
        />
      </div>
    </>
  );
};

export default BudgetNumber;
