const userKind = (user) => {
  if (user.salesCount > 0 && user.salesInterestsCount > 0) {
    return 'buyer_seller'
  } else if (user.salesCount > 0) {
    return 'seller'
  } else if (user.salesInterestsCount > 0) {
    return 'buyer'
  }
}

export default userKind;
