import React from 'react';
import { Route } from 'react-router-dom';

import InterestsIndex from './views/InterestsIndex'
import InterestShow from './views/InterestShow'
import CustomRoutes from '../../../../../shared/utils/customRoutes';

const Interests = () => {
  return (
    <div data-testid='Interests'>
      <CustomRoutes>
        <Route index element={<InterestsIndex />} />
        <Route path=':saleId/*' element={<InterestShow />} />
      </CustomRoutes>
    </div>
  );
}

export default Interests
