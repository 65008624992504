import React from 'react';

import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';

const BuyerAlertForm = () => {
  return (
    <div className='h-screen grid md:grid-cols-2'>
      <LeftPanel />

      <RightPanel />
    </div>
  )
}

export default BuyerAlertForm;
