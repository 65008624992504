import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CriteriasForm from '../../../../../components/BuyerAlertForms/CriteriasForm';

import { Modal, Button } from 'hosman-material';

import { setEditedBuyerAlert, updateBuyerAlert, updateError } from '../../../../../reducers/BuyerApp/buyerAlertsSlice';

const EditModal = () => {
  const { editedBuyerAlert, isSubmitting, errors } = useSelector((state) => state.buyerBuyerAlerts);
  const [stateBuyerAlert, setStateBuyerAlert] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setStateBuyerAlert(editedBuyerAlert)
  }, [editedBuyerAlert]);

  const formatBuyerAlert = () => {
    return {
      ...stateBuyerAlert,
      max_budget: formatToNumberType(stateBuyerAlert.max_budget),
      min_budget: formatToNumberType(stateBuyerAlert.min_budget),
      min_area: formatToNumberType(stateBuyerAlert.min_area),
    };
  }

  const formatToNumberType = (value) => {
    if (typeof value === 'string') return Number(value.replace(/\s+/g, ""));
    return value;
  }

  const handleSetStateBuyerAlert = (buyerAlert) => {
    setStateBuyerAlert(buyerAlert);

    Object.keys(buyerAlert).forEach((key) => {
      if (errors[key]) dispatch(updateError({ ...errors, [key]: null }));
    });
  }

  return (
    <Modal isOpen={editedBuyerAlert.id != null} closeModal={() => dispatch(setEditedBuyerAlert({}))}>
      <div className='flex flex-col gap-6 p-7 md:p-10 max-w-2xl' data-testid='EditModal'>
        <p className='h2 font-bold text-ih-indigo text-center'>Modification de votre alerte achat</p>

        <CriteriasForm buyerAlert={stateBuyerAlert} setStateBuyerAlert={handleSetStateBuyerAlert} errors={errors} />

        <Button
          label='Enregistrer mes critères'
          onClick={() => dispatch(updateBuyerAlert({ id: stateBuyerAlert.id, params: formatBuyerAlert(), action: 'edit' }))}
          isLoading={isSubmitting} />
      </div>
    </Modal>
  )
}

export default EditModal;
