import React from 'react';
import { Link } from 'react-router-dom';
import { generateLink } from '../../../../../../../../shared/utils/railsRoutes';

import PropertySummary from '../../../../../../../components/Property/PropertySummary';
import { FadeIn, Label, PriceBox, Button } from 'hosman-material';
import setStateInfos from '../../utils/setStateInfos';

const Property = ({ sale, visits, offers }) => {
  const stateInfos = setStateInfos(sale, visits, offers);

  if (!stateInfos) return null;
  return (
    <div className='max-w-5xl' data-testid='Property'>
      {sale.id &&
        <FadeIn>
          <PropertySummary
            property={sale.property}
            cityLabel={<Label color={stateInfos.color}>{stateInfos.label}</Label>}
            avatar={sale.expert?.photo}
            link={stateInfos.enrichedAdvert ? generateLink(RailsRoutes.post_visit_adverts_property_path(sale.property.slug)) : generateLink(RailsRoutes.adverts_property_path(sale.property.slug))}
          >
            <div className='w-1/2 md:w-max mx-auto md:mx-0 mb-3 md:mb-0'>
              <PriceBox title='Prix de vente' price={sale.price.toLocaleString()} />
            </div>

            <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-3 md:mb-0'>
              {stateInfos.link && <Link to={stateInfos.link} target='_blank' onClick={(e) => e.stopPropagation()}><Button label={stateInfos.linkText} /></Link>}

              <Button label="Voir l'annonce" type={stateInfos.link ? 'tertiary' : 'primary'} />
            </div>
          </PropertySummary>
        </FadeIn>
      }
    </div>
  )
}

export default Property;
