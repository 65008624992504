import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { D_ArrowDownToBracket } from 'hosman-material';
import { house, folder } from '../../../../../../../../../shared/assets/emojis';

import OfferStateLabel from '../../../../../../../../components/Offer/StateLabel.js';
import Characteristics from '../../../../../../../../../shared/components/Property/Characteristics';
import { Modal, Box, Emoji, PriceBox, Button, BulletPoint, Separator } from 'hosman-material';

import OFFERS from '../../../../../../../../api/ressources/BuyerApp/offers';

const OfferModal = ({ modalOffer, closeModal }) => {
  const [offer, setOffer] = useState(null);

  useEffect(() => {
    OFFERS.getOffer(modalOffer.id).then((response) => {
      setOffer(response.data);
    });
  }, []);

  return (
    <Modal isOpen closeModal={() => closeModal()} width='w-full md:w-2/3'>
      {offer && (
        <div data-testid='OfferModal'>
          <div className='bg-hosman-gradient-1 p-6'>
            <div className='flex items-center space-x-3'>
              <Box>
                <Emoji emojiSrc={house} width='w-5' />
              </Box>
              <span className='h2 text-ih-indigo'>Votre offre</span>
              <div className='hidden md:inline-block'>
                <OfferStateLabel offer={offer} />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-8 gap-6 mt-5'>
              <img className='md:col-span-1 object-cover aspect-[3/1] md:aspect-[1/1] rounded-xl md:w-32' src={offer.sale.property.property_pictures[0].photo_url} />

              <div className='md:hidden'>
                <OfferStateLabel offer={offer} />
              </div>

              <div className='md:col-span-4'>
                <p className='text-sm font-semibold'>{offer.sale.property.advert.title}</p>
                <div className='mt-6'>
                  <Characteristics property={offer.sale.property} />
                </div>
              </div>

              <p className='md:hidden text-sm font-semibold'>Montant de l'offre</p>
              <div className='md:col-span-3 flex space-x-2 md:mt-16'>
                <div className='h-min border rounded-2xl border-ih-indigo-10'>
                  <PriceBox title='Prix de vente' color='text-ih-indigo' price={offer.sale.price.toLocaleString()} />
                </div>
                <div className='h-min border rounded-2xl border-ih-indigo-10'>
                  <PriceBox title='Votre offre' price={offer.amount.toLocaleString()} />
                </div>
              </div>
            </div>
          </div>
          <div className='py-8 px-6 text-sm'>
            <p className='h4 text-ih-blue py-5'>Vos motivations</p>
            <p>{offer.buyer_presentation}</p>

            <Separator margins='my-8' />

            <p className='h4 text-ih-blue pb-5'>Votre plan de financement</p>

            <div>
              <div className='flex items-baseline mb-2'>
                <BulletPoint />
                <p className='text-sm font-semibold inline-block'>Comptez-vous financer cet achat par un paiement comptant en intégralité ?</p>
              </div>

              {offer.financial_profile.cash_payment ? (
                <div>
                  <p>Oui</p>
                  {offer.financial_profile.cash_payment && offer.financial_profile.proof_of_funds_doc_url && (
                    <div className='my-5'>
                      <Emoji emojiSrc={folder} width='w-4' />
                      <p className='text-sm font-semibold inline-block mb-4 ml-1'>Documents associés</p>
                      <a href={offer.financial_profile.proof_of_funds_doc_url} className='flex gap-3 underline mr-2' target='_blank'>
                        Preuve de financement
                        <FontAwesomeIcon icon={D_ArrowDownToBracket} size='lg' className='text-ih-blue' />
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <p>Non</p>
              )}
            </div>

            {!offer.financial_profile.cash_payment && (
              <div>
                <div className='my-7'>
                  <div className='flex items-baseline mb-2'>
                    <BulletPoint />
                    <p className='text-sm font-semibold inline-block'>
                      Avez-vous vendu ou comptez-vous vendre un bien pour financer cette acquisition ?
                    </p>
                  </div>

                  {offer.financial_profile.real_estate_sale ? (
                    <div>
                      <p>Oui</p>
                      <div className='my-5 md:ml-7'>
                        <p className='text-sm font-semibold'>Pour quel montant l'avez-vous vendu ou espérez-vous le vendre ?</p>
                        <p>{offer.financial_profile.real_estate_amount.toLocaleString()} €</p>
                        {offer.financial_profile.proof_of_sale_doc_url && (
                          <div className='my-5'>
                            <Emoji emojiSrc={folder} width='w-4' />
                            <p className='text-sm font-semibold inline-block mb-4 ml-1'>Documents associés</p>
                            <a href={offer.financial_profile.proof_of_sale_doc_url} className='flex gap-3 underline mr-2' target='_blank'>
                              Document
                              <FontAwesomeIcon icon={D_ArrowDownToBracket} size='lg' className='text-ih-blue' />
                            </a>
                          </div>
                        )}
                        <div className='my-5'>
                          <p className='text-sm font-semibold mb-1'>
                            Comment comptez-vous financer le montant restant ({(offer.amount - offer.financial_profile.real_estate_amount).toLocaleString()} €) ?
                          </p>
                          <p>{offer.financial_profile.loan ? 'Par un emprunt' : 'Intégralement en fonds propres'}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>Non</p>
                  )}
                </div>

                <div className='my-7'>
                  <div className='flex items-baseline mb-2'>
                    <BulletPoint />
                    <p className='text-sm font-semibold inline-block'>Comptez-vous faire un emprunt pour financer cet achat ?</p>
                  </div>

                  {offer.financial_profile.loan ? (
                    <div>
                      <p>Oui</p>
                      <div className='my-5 md:ml-7'>
                        <p className='text-sm font-semibold mb-1'>Souhaitez-vous inclure une condition suspensive d'obtention de prêt ?</p>
                        <div>{offer.financial_profile.loan_condition ? 'Oui' : 'Non'}</div>

                        <div className='my-5'>
                          <p className='text-sm font-semibold mb-1'>Apport personnel pour cet achat</p>
                          {`${offer.financial_profile.apport.toLocaleString()} €`}
                        </div>

                        <div className='my-5 flex justify-start gap-5'>
                          <div>
                            <p className='text-sm font-semibold mb-1'>Revenus nets mensuels de votre foyer</p>
                            {`${offer.financial_profile.revenues.toLocaleString()} €`}
                          </div>

                          <div>
                            <p className='text-sm font-semibold mb-1'>Charges mensuelles récurrentes du foyer</p>
                            {`${offer.financial_profile.charges.toLocaleString()} €`}
                          </div>
                        </div>

                        {offer.financial_profile.promise_of_financing_doc_url && (
                          <div className='my-5'>
                            <Emoji emojiSrc={folder} width='w-4' />
                            <p className='text-sm font-semibold inline-block mb-4 ml-1'>Documents associés</p>
                            <a href={offer.financial_profile.promise_of_financing_doc_url} className='flex gap-3 underline mr-2' target='_blank'>
                              Attestation de financement
                              <FontAwesomeIcon icon={D_ArrowDownToBracket} size='lg' className='text-ih-blue' />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <p>Non</p>
                  )}
                </div>
              </div>
            )}

            <div className='text-center mt-10'>
              <Button label='Revenir à mes offres' onClick={() => closeModal()} />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default OfferModal;

// PropTypes
OfferModal.propTypes = {
  modalOffer: PropTypes.object.isRequired,
  closeModal: PropTypes.func
};
