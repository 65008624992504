import React from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Button } from 'hosman-material';
import { D_BellOn, S_Check, S_Bell } from 'hosman-material';

import { setNewModalOpen } from '../../../../../reducers/BuyerApp/buyerAlertsSlice';

const NoBuyerAlerts = () => {
  const dispatch = useDispatch();

  return (
    <div className='flex flex-col gap-6 p-10 border border-ih-indigo-10 rounded-2xl text-center w-max mx-auto' data-testid='NoBuyerAlerts'>
      <div className='mx-auto'>
        <Box><FontAwesomeIcon icon={D_BellOn} size='xl' className='text-ih-blue' /></Box>
      </div>

      <p className='h2 text-ih-indigo'>Vous n'avez pas <span className='text-ih-blue'>d'alerte achat</span></p>

      <div className='flex flex-col gap-3'>
        <div className='flex justify-center items-center gap-2'>
          <FontAwesomeIcon icon={S_Check} className='text-xs flex items-center justify-center p-1 rounded-full bg-ih-green text-white' />
          <p className='text-ih-indigo'>Recevez en avant-première par email les nouveaux biens qui correspondent à vos critères</p>
        </div>
        <div className='flex justify-center items-center gap-2'>
          <FontAwesomeIcon icon={S_Check} className='text-xs flex items-center justify-center p-1 rounded-full bg-ih-green text-white' />
          <p className='text-ih-indigo'>Accédez à ces annonces avant leur diffusion sur les sites immobiliers</p>
        </div>
      </div>

      <div>
        <Button label='Créer une alerte achat' icon={S_Bell} onClick={() => dispatch(setNewModalOpen(true))} />
      </div>
    </div>
  )
}

export default NoBuyerAlerts;
