import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Error } from './Error';

import { S_Check } from 'hosman-material';

const PropertyKindCheckbox = ({ buyerAlert, setStateBuyerAlert, errors }) => {
  const handleChange = (property) => {
    setStateBuyerAlert({
      ...buyerAlert,
      [property]: !buyerAlert[property]
    });
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center gap-1'>
        <p className='font-semibold'>Que recherchez-vous ? </p>
        <p className='text-xs'>(Plusieurs choix possibles)</p>
      </div>

      <div className='flex flex-col'>
        <div className='flex gap-2'>
          {['apartment', 'house'].map((property) => (
            <label
              key={property}
              className={`flex items-center justify-center cursor-pointer text-ih-indigo font-semibold bg-white p-3 rounded-2xl border ${
                buyerAlert[property]
                  ? 'border-2 border-ih-blue'
                  : 'border-ih-blue-30 hover:border-ih-blue-80'
              }`}
            >
              <div className='flex items-center gap-2'>
                <div className='flex items-center justify-center'>
                  <input
                    type='checkbox'
                    className='sr-only'
                    checked={buyerAlert[property] || false}
                    onChange={() => handleChange(property)}
                  />

                  {buyerAlert[property] ? <FontAwesomeIcon icon={S_Check} className='bg-ih-green text-white rounded-md p-1' /> : <div className='h-6 w-6 border border-ih-blue-30 rounded-md p-1'></div>}
                </div>

                <span className='text-sm font-medium text-gray-700'>
                  {property === 'apartment' ? 'Appartement' : 'Maison'}
                </span>
              </div>
            </label>
          ))}
        </div>

        {(errors.apartment && errors.house) && (
          <Error name='apartment' errors={errors} label='' />
        )}
      </div>
    </div>
  );
};

export default PropertyKindCheckbox;
