import React from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import Step1 from './components/Step1';
import Step2 from './components/Step2';

const LeftPanel = () => {
  const { showContactDetailsForm} = useSelector((state) => state.buyerAlertCreator);

  return (
    <div className='w-full bg-ih-blue-10 hidden md:flex flex-col justify-center items-center'>
      <div className='w-2/3 mx-auto'>
        <div className='flex flex-col items-center justify-center gap-3 text-ih-indigo'>
          <AnimatePresence  mode='wait'>
            {showContactDetailsForm ? (
              <motion.div
                key='Step2'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Step2 />
              </motion.div>
            ) : (
              <motion.div
                key='Step1'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Step1 />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
