import React from 'react';

import { ArticleCard } from 'hosman-material';

const Partners = ({ partners }) => {
  return (
    <div className='flex flex-col gap-6' data-testid='Partners'>
      <div className='h2 md:w-1/2 mx-auto text-center text-ih-indigo'>
        Nos partenaires vous accompagnent dans votre projet d'achat
      </div>

      <div className='grid grid-cols-1 md:flex md:justify-center md:flex-wrap md:gap-8' data-testid='partner-cards'>
        {partners.map((partner) => (
          <div key={partner.id} className='mb-10 xl:mb-0'>
            <ArticleCard
              href={partner.link}
              imageSrc={partner.card_picture_url}
              labelContent={partner.category.title}
              labelColor='bg-ih-indigo'
              title={partner.partner_name}
              titleColor='text-ih-blue-80'
            >
              {partner.description}
            </ArticleCard>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Partners;
