import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateLink } from '../../../../../../../shared/utils/railsRoutes';

import EstimationBanner from '../../../../../../components/Marketing/EstimationBanner';
import Criterias from './components/Criterias';
import CorrespondingProperties from './components/CorrespondingProperties';
import SponsorshipCard from './components/SponsorshipCard';
import AdvertsCard from './components/AdvertsCard';

import { HosmanLogo } from 'hosman-material';
import { S_Check } from 'hosman-material';

const BuyerAlertSuccess = () => {
  const { createdBuyerAlertData } = useSelector((state) => state.buyerAlertCreator);

  if (!createdBuyerAlertData.id) window.location = generateLink(RailsRoutes.root_path())

  return (
    <div>
      <div className='h-14 px-5 bg-ih-indigo flex items-center'>
        <a href={generateLink(RailsRoutes.root_path())}>
          <HosmanLogo rgbColor='#ffffff' />
        </a>
      </div>

      <div className='flex flex-col items-center gap-12 pb-20'>
        <div className='w-full bg-hosman-gradient-1 py-20'>
          <div className='flex flex-col items-center gap-3 max-w-md mx-auto'>
            <div className='h-6 w-6 flex justify-center items-center bg-ih-green rounded-full'>
              <FontAwesomeIcon icon={S_Check} className='text-white' />
            </div>

            <p className='h2 text-center'>Félicitations, votre <span className='text-ih-blue'>alerte achat</span> a bien été <span className='text-ih-blue'>enregistrée</span></p>

            <Criterias data={createdBuyerAlertData} />
          </div>
        </div>

        <CorrespondingProperties buyerAlertId={createdBuyerAlertData.id} />

        <EstimationBanner gtmEvent='start_estimation_buyer' userId={createdBuyerAlertData.user.id} />

        <div className='flex flex-col md:flex-row justify-center items-center gap-7'>
          <SponsorshipCard />
          <AdvertsCard />
        </div>
      </div>
    </div>
  )
}

export default BuyerAlertSuccess;
