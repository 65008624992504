import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import OFFERS from '../../api/ressources/BuyerApp/offers';

export const getOffers = createAsyncThunk(
  '/getOffers',
  async () => {
    const response = await OFFERS.getOffers()
    return response.data
  }
);

const initialState = {
  allOffers: [],
  offersState: ''
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetOffers(state) {
      state.allOffers = initialState.allOffers;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOffers.pending, (state) => {
      state.offersState = 'pending'
    })
    builder.addCase(getOffers.fulfilled, (state, { payload }) => {
      state.allOffers = payload.results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      state.offersState = 'done'
    })
  },
})

export const { resetOffers } = offersSlice.actions;

export default offersSlice.reducer;
