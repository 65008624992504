import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import SALES_INTERESTS from '../../api/ressources/BuyerApp/salesInterests';

export const getSalesInterests = createAsyncThunk(
  '/getSalesInterests',
  async (params) => {
    const response = await SALES_INTERESTS.getSalesInterests();
    return response.data
  }
);

const initialState = {
  salesInterests: [],
  saleInterestsState: ''
};

export const salesInterestsSlice = createSlice({
  name: 'salesInterests',
  initialState,
  reducers: {
    resetSalesInterests(state) {
      state.salesInterests = initialState.salesInterests;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSalesInterests.pending, (state) => {
      state.salesInterests = initialState.salesInterests;
      state.saleInterestsState = 'pending'
    })
    builder.addCase(getSalesInterests.fulfilled, (state, { payload }) => {
      state.salesInterests = payload
      state.saleInterestsState = 'done'
    })
  },
})

export const { resetSalesInterests } = salesInterestsSlice.actions;

export default salesInterestsSlice.reducer;
