import { generateLink } from '../../../../../../../shared/utils/railsRoutes';
import { shortDateAndYear } from '../../../../../../../shared/utils/formattedDates';

export default (sale, visits, offers) => {
  const offerInfos = (offer) => {
    switch (offer.review_status) {
      case 'pending':
      case 'ongoing':
      case 'unsent_for_review':
      case 'partly_accepted':
        return { enrichedAdvert: true, label: 'Offre remise', color: 'bg-ih-blue', link: `/ea/interests/${sale.id}/mes-offres`, linkText: 'Visualiser mon offre' }
      case 'accepted':
        return { enrichedAdvert: true, label: 'Offre acceptée', color: 'bg-ih-green' }
      case 'refused':
        return { enrichedAdvert: true, label: 'Offre refusée', color: 'bg-ih-red', link: generateLink(RailsRoutes.online_offers_path({ property_slug: sale.property.slug })), linkText: 'Faire une contre offre' }
      case 'dropped':
        return { enrichedAdvert: true, label: 'Offre annulée', color: 'bg-ih-red' }
      default:
        return { enrichedAdvert: true, label: 'Offre non finalisée', color: 'bg-ih-orange' }
    }
  }

  const visitInfos = (visit) => {
    if (!visit) return null;

    if (visit.canceled) return { enrichedAdvert: true, label: 'Visite annulée', color: 'bg-ih-red', link: generateLink(RailsRoutes.online_offers_path({ property_slug: sale.property.slug })), linkText: 'Faire une offre' }
    if (['proposed', 'confirmed'].includes(visit.state)) return { enrichedAdvert: false, label: `Visite prévue le ${shortDateAndYear(visit.start_time)}`, color: 'bg-ih-blue' }
    if (visit.state === 'done') return { enrichedAdvert: true, label: 'Visite effectuée', color: 'bg-ih-green', link: generateLink(RailsRoutes.online_offers_path({ property_slug: sale.property.slug })), linkText: 'Faire une offre' }
  }

  const setStateInfos = () => {
    const lastOffer = offers[0]
    if (lastOffer) return offerInfos(lastOffer)

    const selectedVisit = selectVisit(visits)
    return visitInfos(selectedVisit)
  }

  return setStateInfos();
}

function selectVisit(visits) {
  // If there are confirmed visit to come, select the one to come first
  const futureVisits = visits.filter(visit => (visit.state === 'confirmed' && new Date(visit.start_time) > new Date()))
  futureVisits.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
  if (futureVisits.length > 0) return futureVisits[0]

  // If there are no confirmed visit to come, display the last one the is done
  const pastVisits = visits.filter(visit => (visit.state === 'done' && new Date(visit.start_time) <= new Date()))
  pastVisits.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
  if (pastVisits.length > 0) return pastVisits[0]
  // If there are no confirmed visit to come neither done, display the first one (otherwise it's null and error occurs when trying to access object properties like "stateInfos.color")
  if (visits.length > 0) return visits[0]

  return null;
}
