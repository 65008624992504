import React from 'react';
import { Route } from 'react-router-dom';
import CustomRoutes from '../../../../../shared/utils/customRoutes';

import BuyerAlertForm from './pages/BuyerAlertForm';
import BuyerAlertSuccess from './pages/BuyerAlertSuccess';

const BuyerAlertCreator = () => {
  return (
    <CustomRoutes>
      <Route index element={<BuyerAlertForm />} />
      <Route path='/success' element={<BuyerAlertSuccess />} />
    </CustomRoutes>
  )
}

export default BuyerAlertCreator;
