import React from 'react';
import { TextAreaInput } from 'hosman-material';

const FreeFieldTextArea = ({ buyerAlert, setStateBuyerAlert }) => {
  return (
    <TextAreaInput
      name='free_input_field'
      label={<p>D'autres critères à nous partager ? <span className='text-xs font-normal'>(Optionnel)</span></p>}
      placeholder="Horizon du projet d'achat, contexte d'achat..."
      onChange={(e) => setStateBuyerAlert({...buyerAlert, free_input_field: e.target.value})}
      rows={4}
      value={buyerAlert.free_input_field || ''}
    />
  )
}

export default FreeFieldTextArea;
