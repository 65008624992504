import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, useParams, useNavigate, Navigate } from 'react-router-dom';
import { navigation } from './navigation';
import { actionConsumer } from '../../../../shared/channels/consumer';
import CustomRoutes from '../../../../shared/utils/customRoutes';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { LoadingLayer, FadeIn, ContactCardMinimize, PropertySelect } from 'hosman-material';

import Sale from './pages/Sale';
import Estimation from './pages/Estimation';
import SaleFolder from './pages/SaleFolder';
import Planning from './pages/Planning';
import VisitReports from './pages/VisitReports';
import Offers from './pages/Offers';
import Advices from './pages/Advices';
import Sponsorship from './pages/Sponsorship';

import { purgeEstimationData, updateStep } from '../../../reducers/_Pages/Public/estimationFunnelSlice';
import { getSale, updateSale, resetSale, setPropertySelectOptions, getPublicationData, resetPublicationData } from '../../../reducers/SellerApp/salesSlice';
import { getEstimationFolder, resetEstimationFolder } from '../../../reducers/SellerApp/estimationFoldersSlice';
import { getOffers, resetOffers } from '../../../reducers/SellerApp/offersSlice';
import { getPhotoMeetings, resetPhotoMeetings } from '../../../reducers/SellerApp/photoMeetingsSlice';
import { getVisits, resetVisits } from '../../../reducers/SellerApp/visitsSlice';

const SaleShow = ({ setNavigation, selectedNav }) => {
  const { sales, sale, propertySelectOptions, showRequestState } = useSelector((state) => state.sellerSales);
  const { estimationFolder } = useSelector((state) => state.sellerEstimationFolders);
  const { offers } = useSelector((state) => state.sellerOffers);
  const { visits, visitsWithReport, visitsToCome } = useSelector((state) => state.sellerVisits);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { saleId } = useParams();

  useEffect(() => {
    dispatch(purgeEstimationData())
  }, []);

  useEffect(() => {
    if (!sale.id || !actionConsumer) return;

    actionConsumer.subscriptions.create(
      { channel: 'NapoleonNotificationsChannel' },
      {
        received: (data) => {
          dispatch(updateSale({ ...sale, expert: data.expert }));
        }
      }
    );
  }, [sale.id]);

  useEffect(() => {
    setNavigation(navigation({
      sale: sale,
      estimationFolder: estimationFolder,
      visitsToCome: visitsToCome,
      visitsWithReport: visitsWithReport,
      offers: offers
    }))

  }, [sale.id, estimationFolder, offers, visits, visitsWithReport, visitsToCome]);

  useEffect(() => {
    dispatch(getSale(saleId));

    return function cleanup() {
      dispatch(resetSale());
    };
  }, [saleId]);

  useEffect(() => {
    if (!sale.id) return;

    if (sale.state === 'awaiting_term_signature' && sale.onboarding_url) window.location.href = sale.onboarding_url
  }, [sale.id]);

  useEffect(() => {
    dispatch(setPropertySelectOptions());
  }, [sales, sale.id]);

  useEffect(() => {
    if (!sale.id) return;

    if (sale.estimation_folder_id) {
      dispatch(getEstimationFolder(sale.estimation_folder_id));
    }

    if (sale.mandateSigned) {
      dispatch(getOffers({ 'q[sale_id_eq]': sale.id }));
      dispatch(getPhotoMeetings(sale.id));
      dispatch(getVisits(sale.property.id));
      dispatch(getPublicationData(sale.id));
    }

    return function cleanup() {
      dispatch(resetEstimationFolder());
      dispatch(resetOffers());
      dispatch(resetPhotoMeetings());
      dispatch(resetVisits());
      dispatch(resetPublicationData());
    };
  }, [sale.id]);

  return (
    <div data-testid='SaleShow'>
      <PageTitle icon={selectedNav.icon} title={selectedNav.name}>
        {sale.id &&
          <>
            {sale.expert &&
              <ContactCardMinimize
                firstName={sale.expert.user.first_name}
                imageSrc={sale.expert?.user?.profile_pic_url}
                mail={sale.expert.user.email}
                phoneNumber={sale.expert.user.phone_number}
              />
            }

            <PropertySelect
              selectedOption={{
                address: `${sale.property.street_number} ${sale.property.street_name} ${sale.property.zip_code} ${sale.property.city}`,
              }}
              options={propertySelectOptions}
              handleChange={(sale) => navigate(`/ev/ventes/${sale.id}`)}
            />
          </>
        }
      </PageTitle>

      <LoadingLayer isReady={showRequestState === 'done'}>
        <FadeIn>
          <CustomRoutes>
            {sale.mandateSigned && <Route index element={<Sale />} />}
            {sale.property?.kind !== 'parking_spot' && <Route index element={<Navigate to='mon-estimation' />} />}
            {sale.property?.kind !== 'parking_spot' && <Route path='/mon-estimation/*' element={<Estimation />} />}
            <Route path='/dossier-de-vente/*' element={<SaleFolder />} />
            <Route path='/planning' element={<Planning />} />
            <Route path='/comptes-rendus' element={<VisitReports />} />
            <Route path='/offres' element={<Offers />} />
            <Route path='/conseils-immo' element={<Advices />} />
            <Route path='/parrainage' element={<Sponsorship />} />
          </CustomRoutes>
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

// PropTypes
SaleShow.propTypes = {
  clientSwitch: PropTypes.func,
  accountCard: PropTypes.func
}

export default SaleShow;
