import React from 'react';

import { Label } from 'hosman-material';

const Localisation = ({ zones }) => {
  return (
    <div className='col-span-2 flex flex-col gap-5 py-3 md:px-3' data-testid='Localisation'>
      <p className='text-ih-indigo-60'>Localisation</p>
      <div className='flex flex-wrap gap-3'>
        {zones.map(zone => {
          return <Label key={zone} color='bg-ih-blue'>{zone}</Label>
        })}
      </div>
    </div>
  )
}

export default Localisation;
