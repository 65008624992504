import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Checkbox } from 'hosman-material';

import { updateStep, updateEstimationData, updateErrors } from '../../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const WaitingTimePeriodSelector = () => {
  const { estimationData, estimationData: { waitingTimePeriod }} = useSelector((state) => state.estimationFunnel);
  const dispatch = useDispatch();

  const periods = [
    { slug: 'asap', name: 'Dès que possible' },
    { slug: 'less_than_six_months', name: 'Dans les 6 prochains mois' },
    { slug: 'more_than_six_months', name: 'Dans plus de 6 mois' },
    { slug: 'no_idea', name: 'Je ne sais pas, je démarre seulement ma réflexion' },
    { slug: 'no_selling_project', name: "Je n'ai pas de projet de vente" }
  ]

  const handleChange = (period) => {
    dispatch(updateErrors([]));
    dispatch(updateEstimationData({ ...estimationData, waitingTimePeriod: period.slug, nonSellingReason: null }))
    if (period.slug !== 'no_selling_project') dispatch(updateStep({ stepNumber: 6 }))
  }

  return (
    <div className='flex flex-col gap-4' data-testid='WaitingTimePeriodSelector'>
      <p className='h3 text-ih-indigo'>Quand souhaitez vous vendre ce bien ?</p>

      <div className='border border-ih-purple-20 rounded-xl'>
        {periods.map(period => {
          return (
            <div
              key={period.slug}
              className='p-4 bg-white hover:bg-ih-purple-5 border-b border-ih-purple-20 last:border-b-0 first:rounded-t-xl last:rounded-b-xl cursor-pointer'
              onClick={() => handleChange(period)}
            >
              <Checkbox
                key={period.slug}
                name={period.slug}
                label={period.name}
                isChecked={period.slug === waitingTimePeriod}
                readOnly
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default WaitingTimePeriodSelector;
