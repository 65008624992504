import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import CriteriasForm from '../../../../../../../../../components/BuyerAlertForms/CriteriasForm';

import { S_Bell } from 'hosman-material';
import { Button } from 'hosman-material';

import { updateStoreBuyerAlert, validateBuyerAlert, updateErrors } from '../../../../../../../../../reducers/_Pages/Public/buyerAlertCreatorSlice';

const CriteriaStep = ({ rightPanelRef }) => {
  const { storeBuyerAlert, errors } = useSelector((state) => state.buyerAlertCreator);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(location.pathname, { replace: true });

    rightPanelRef.current.scrollTo({ top: 0 })
  }, []);

  const handleSaveCriterias = async () => {
    dispatch(validateBuyerAlert(storeBuyerAlert));

    setTimeout(() => {
      rightPanelRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }, 300);
  }

  const handleSetStateBuyerAlert = (buyerAlert) => {
    dispatch(updateStoreBuyerAlert(buyerAlert));
    Object.keys(buyerAlert).forEach((key) => {
      if (errors[key]) dispatch(updateErrors({ ...errors, [key]: null }));
    });
  }

  return (
    <div className='flex flex-col gap-12'>
      <CriteriasForm buyerAlert={storeBuyerAlert} setStateBuyerAlert={handleSetStateBuyerAlert} errors={errors} />

      <div className='flex justify-end'>
        <Button label='Enregistrer mes critères' onClick={handleSaveCriterias} icon={S_Bell} />
      </div>
    </div>
  );
};

export default CriteriaStep;
