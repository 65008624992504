import React from 'react';
import { propertyCardCarousel } from '../../../../../../../../shared/assets/images';
import { generateLink } from '../../../../../../../../shared/utils/railsRoutes';

import { Button } from 'hosman-material';

const AdvertsCard = () => {
  return (
    <a href={generateLink(RailsRoutes.annonces_path())} target='_blank'>
      <div className='flex flex-col gap-3 text-ih-indigo border-2 border-ih-purple-20 hover:border-ih-purple-40 bg-hosman-gradient-1 rounded-3xl p-7' data-testid='AdvertsCard'>
        <p className='text-xl font-bold text-ih-indigo text-center w-72'>Découvrez nos derniers biens à vendre</p>

        <p className='text-center text-ih-blue underline'>Voir toutes nos annonces</p>

        <img src={propertyCardCarousel} alt='Buyer alert cards' className='mx-auto w-60' />
      </div>
    </a>
  );
};

export default AdvertsCard;
