import React from 'react';
import { useSelector } from 'react-redux';
import { smilingFace } from '../../../../../shared/assets/emojis'

import PageTitle from '../../../../components/PageTitle/PageTitle';
import NextVisit from './components/NextVisit';
import LastOffer from './components/LastOffer';
import LastBuyerAlert from './components/LastBuyerAlert';
import EstimationBanner from '../../../../components/Marketing/EstimationBanner';
import BuyerAlertCard from './components/BuyerAlertCard';
import AdvertsCard from './components/AdvertsCard';
import SponsorshipCard from './components/SponsorshipCard';
import { greeting } from '../../../../../shared/utils/helpers/hosmanVars';

import { LoadingLayer, FadeIn, Emoji, Separator } from 'hosman-material';
import { D_House } from 'hosman-material';

const Home = () => {
  const { user } = useSelector((state) => state.user);
  const { saleInterestsState } = useSelector((state) => state.buyerSalesInterests);
  const { allOffers, offersState } = useSelector((state) => state.buyerOffers);
  const { buyerAlerts, buyerAlertsState } = useSelector((state) => state.buyerBuyerAlerts);
  const { allVisits, visitsState } = useSelector((state) => state.buyerVisits);

  const selectedVisit = selectDisplayedVisit(allVisits);
  const selectedOffer = allOffers[0];

  return (
    <div data-testid='Home'>
      <PageTitle icon={D_House} title='Accueil' />

      <div className='min-h-60'>
        <LoadingLayer isReady={[saleInterestsState, visitsState, offersState, buyerAlertsState].every(state => state === 'done')}>
          <FadeIn>
            <div className='flex flex-col gap-6'>
              <p className='h2 mb-8 text-ih-indigo text-center md:text-left'>Bonjour {greeting(user)}<span className='ml-2'><Emoji emojiSrc={smilingFace} width='w-8' /></span></p>

              <div className='flex flex-col gap-12'>
                <LastOffer offer={selectedOffer} offers={allOffers} />

                <NextVisit visit={selectedVisit} visits={allVisits} />

                <LastBuyerAlert />
              </div>
            </div>
          </FadeIn>
        </LoadingLayer>
      </div>

      <Separator />

      <div className='flex flex-col gap-12'>
        <div className='flex justify-center'>
          <EstimationBanner gtmEvent='start_estimation_buyer' userId={user.id} />
        </div>

        <div className='flex flex-col md:flex-row justify-center items-center gap-7'>
          <SponsorshipCard />
          {buyerAlerts.length > 0 ? <AdvertsCard /> : <BuyerAlertCard />}
        </div>
      </div>
    </div>
  )
};

export default Home;

function selectDisplayedVisit(allVisits) {
  // If there are confirmed visit to come, display the one to come first
  const futureVisits = allVisits.filter(visit => (['proposed', 'confirmed'].includes(visit.state) && new Date(visit.start_time) > new Date()))
  futureVisits.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
  if (futureVisits.length > 0) return futureVisits[0]

  // If there are no confirmed visit to come, display the last one the is done
  const pastVisits = allVisits.filter(visit => (visit.state === 'done' && new Date(visit.start_time) <= new Date()))
  pastVisits.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
  if (pastVisits.length > 0) return pastVisits[0]

  return {}
}
