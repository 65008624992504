import React from 'react';
import { useSelector } from 'react-redux';

import ArticlesList from '../../../../../components/Blog/ArticlesList';

const Articles = () => {
  const { articles } = useSelector((state) => state.articles);
  const { user } = useSelector((state) => state.user);

  return (
    <div className='flex flex-col gap-6' data-testid='Articles'>
      <div className='h2 md:w-1/2 mx-auto text-center text-ih-indigo'>
        Nos meilleurs conseils pour votre projet immobilier
      </div>

      <ArticlesList articles={articles} user={user} />
    </div>
  );
}

export default Articles;
