import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
import { S_Check, S_Xmark } from 'hosman-material';

import BUYER_ALERTS from '../../api/ressources/BuyerApp/buyerAlerts';

export const getBuyerAlerts = createAsyncThunk(
  '/getBuyerAlerts',
  async (params) => {
    const response = await BUYER_ALERTS.getBuyerAlerts()
    return response.data
  }
);

export const createBuyerAlert = createAsyncThunk(
  '/createBuyerAlert',
  async (params, { rejectWithValue }) => {
    try {
      const response = await BUYER_ALERTS.createBuyerAlert(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBuyerAlert = createAsyncThunk(
  '/updateBuyerAlert',
  async ({ id, params, action }, { rejectWithValue }) => {
    try {
      const response = await BUYER_ALERTS.updateBuyerAlert(id, params)
      return { data: response.data, action: action }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  buyerAlerts: [],
  editedBuyerAlert: {},
  newModalOpen: false,
  isSubmitting: false,
  buyerAlertsState: '',
  unsubscriptionBuyerAlert: null,
  errors: {}
};

export const buyerAlertsSlice = createSlice({
  name: 'buyerAlerts',
  initialState,
  reducers: {
    setEditedBuyerAlert: (state, { payload }) => {
      state.editedBuyerAlert = payload;
    },
    setNewModalOpen: (state, { payload }) => {
      state.newModalOpen = payload;
      state.errors = {};
    },
    updateUnsubscriptionBuyerAlert: (state, { payload }) => {
      state.unsubscriptionBuyerAlert = payload;
    },
    updateError: (state, { payload }) => {
      state.errors = payload;
    },
    resetBuyerAlerts(state) {
      state.buyerAlerts = initialState.buyerAlerts;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBuyerAlerts.pending, (state) => {
      state.buyerAlerts = initialState.buyerAlerts;
      state.buyerAlertsState = 'pending';
    })
    builder.addCase(getBuyerAlerts.fulfilled, (state, { payload }) => {
      state.buyerAlerts = order(payload);
      state.buyerAlertsState = 'done';
    })
    builder.addCase(updateBuyerAlert.pending, (state) => {
      state.isSubmitting = true;
    })
    builder.addCase(updateBuyerAlert.fulfilled, (state, { payload }) => {
      const newPayload = [...state.buyerAlerts];
      const updatedIndex = newPayload.findIndex(buyerAlert => buyerAlert.id === payload.data.id);
      newPayload[updatedIndex] = payload.data;
      state.buyerAlerts = order(newPayload);
      state.editedBuyerAlert = initialState.editedBuyerAlert;
      state.isSubmitting = false;

      if (payload.action == 'edit') toast.success(<p>Votre alerte achat a bien été modifiée</p>, { icon: S_Check, duration: 3000 })

      if (payload.action == 'subsciption') {
        state.unsubscriptionBuyerAlert = null;

        if (payload.data.active) {
          toast.success(<p>Vous êtes à nouveau inscrit à cette alerte achat</p>, { icon: S_Check, duration: 3000 })
        } else {
          toast.error(<p>Votre alerte a bien été désactivée</p>, { icon: S_Xmark, duration: 3000 })
        }
      }
    })
    builder.addCase(updateBuyerAlert.rejected, (state, { payload }) => {
      state.isSubmitting = false;
      state.errors = payload.error.message;
    })
    builder.addCase(createBuyerAlert.pending, (state) => {
      state.isSubmitting = true;
    })
    builder.addCase(createBuyerAlert.fulfilled, (state, { payload }) => {
      state.buyerAlerts = [payload, ...state.buyerAlerts];
      state.isSubmitting = false;
      state.newModalOpen = false;

      toast.success(<p>Votre alerte achat a bien été créée</p>, { icon: S_Check, duration: 5000 })
    })
    builder.addCase(createBuyerAlert.rejected, (state, { payload }) => {
      state.isSubmitting = false;
      state.errors = payload.error.message;
    })
  },
})

export const { setEditedBuyerAlert, setNewModalOpen, updateUnsubscriptionBuyerAlert, resetBuyerAlerts, updateError } = buyerAlertsSlice.actions;

export default buyerAlertsSlice.reducer;

function order(buyerAlerts) {
  return buyerAlerts.sort((a, b) => {
    if (a.active === b.active) {
        return new Date(b.created_at) - new Date(a.created_at);
    } else {
        return a.active ? -1 : 1;
    }
  });
}
