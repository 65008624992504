import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { S_CircleCheck } from 'hosman-material';
import { Button, Avatar } from 'hosman-material';
import { ga4Event } from '../../../../../../../../shared/utils/ga4Event';

const ExpertBlock = ({ expert, expertContactRef }) => {
  if (!expert) return null;

  return (
    <div className='flex flex-col gap-7' data-testid='ExpertBlock'>
      <div className='flex flex-col gap-2'>
        <p className='h2 text-ih-indigo'>Vous souhaitez une estimation plus précise ?</p>

        <div className='text-sm text-ih-indigo-60'>
          Échangez au téléphone avec {expert.user.first_name}, l'expert{expert.user.civility == 'mrs' ? 'e' : ''} de votre secteur, pour identifier les spécificités de votre bien.
        </div>
      </div>

      <div className='flex flex-col items-center gap-8' ref={expertContactRef}>
        {expert?.user?.profile_pic_url &&
          <Avatar border imageSrc={expert.user.profile_pic_url} userName={`${expert.user.first_name} ${expert.user.last_name}`} bgColor='bg-ih-blue' size='w-24' />
        }

        <div className='flex flex-col gap-3 items-center'>
          {expert &&
            <Link to={expert.phone_calendar_link} target='_blank' onClick={() => ga4Event('click_phone_appointment')}>
              <Button label={`Être rappelé(e) par ${expert.user.first_name}`} />
            </Link>
          }

          <div className='flex items-center gap-1 w-max mx-auto'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-blue' />
            <p className='text-ih-blue text-sm'>100% gratuit et sans engagement</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpertBlock;
