import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateLink } from '../../../../../../../../../../shared/utils/railsRoutes';
import { longDateTime } from '../../../../../../../../../../shared/utils/formattedDates';

import { Card, Box, Button } from 'hosman-material';
import { D_CalendarStar } from 'hosman-material';

const StateCard = ({ visit, sale, setCancelModalOpen }) => {
  const displayedContent = (date) => {
    switch (visit.state) {
      case 'proposed':
      case 'confirmed':
        return `Visite prévue le ${date}`;
      case 'done':
        return  `Visite effectuée le ${date}`;
      case 'rescheduled':
        return  `Visite du ${date} reportée`;
      case 'cancelled_visitor':
      case 'cancelled_owner':
      case 'cancelled_guide':
      case 'cancelled_terminated':
        return `Visite du ${date} annulée`;
    }
  }

  const displayActions = () => {
    switch (visit.state) {
      case 'confirmed':
        return <Button label='Annuler la visite' type='tertiary' onClick={() => setCancelModalOpen(true)} />
      case 'done':
        return (
          <div className='flex flex-col md:flex-row gap-3'>
            <a href={generateLink(RailsRoutes.online_offers_path(sale.property.slug))} target='_blank' ><Button label='Remettre une offre' /></a>
            <a href={generateLink(RailsRoutes.bookings_slots_path(sale.property.slug))} target='_blank' ><Button label='Faire une contre visite' type='tertiary' /></a>
          </div>
        )
      case 'cancelled_visitor':
      case 'cancelled_owner':
      case 'cancelled_guide':
      case 'cancelled_terminated':
      return <a href={generateLink(RailsRoutes.bookings_slots_path(sale.property.slug))} target='_blank'><Button label='Reprogrammer une visite' /></a>
    }
  }


  return (
    <Card classes='flex flex-col md:flex-row items-center gap-3 md:gap-12 p-5'>
      <div className='flex flex-col md:flex-row items-center gap-3'>
        <Box><FontAwesomeIcon icon={D_CalendarStar} size='xl' className='text-ih-blue' /></Box>
        <div className='flex flex-col gap-1'>
          <p className='font-semibold text-ih-indigo'>{displayedContent(longDateTime({ date: visit.start_time }))}</p>
          {visit.cancel_reason_text && <p className='text-ih-indigo-50'>Motif: {visit.cancel_reason_text}</p>}
        </div>
      </div>

      {displayActions()}
    </Card>
  );
};

export default StateCard;

// PropTypes
StateCard.propTypes = {
  visit: PropTypes.object.isRequired,
  setCancelModalOpen: PropTypes.func.isRequired
};
