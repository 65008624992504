import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'hosman-material';
import { S_Pen, S_Bell, S_BellSlash } from 'hosman-material';

import { setEditedBuyerAlert, updateBuyerAlert, updateUnsubscriptionBuyerAlert } from '../../../../../../../reducers/BuyerApp/buyerAlertsSlice';

const Actions = ({ buyerAlert }) => {
  const dispatch = useDispatch();

  return (
    <div data-testid='Actions'>
      <div className='flex flex-col md:flex-row justify-center gap-3'>
        <Button label='Modifier mes critères' icon={S_Pen} type='custom' iconColor='text-ih-indigo' customColors='bg-ih-purple-20 hover:bg-ih-purple-30 text-ih-indigo border-none' onClick={() => dispatch(setEditedBuyerAlert(buyerAlert))} />

        {buyerAlert.active &&
          <Button
            label='Me désinscrire de cette alerte'
            icon={S_BellSlash}
            type='custom'
            customColors='bg-ih-red-10 hover:bg-ih-red-20 text-ih-indigo border-none'
            iconColor='text-ih-red'
            onClick={() => dispatch(updateUnsubscriptionBuyerAlert(buyerAlert))}
          />
        }

        {!buyerAlert.active &&
          <Button
            label='Réinscription à cette alerte'
            icon={S_Bell}
            type='custom'
            customColors='bg-ih-green-20 hover:bg-ih-green-20 text-ih-indigo border-none'
            iconColor='text-ih-green'
            onClick={() => dispatch(updateBuyerAlert({ id: buyerAlert.id, params: { ...buyerAlert, active: !buyerAlert.active }, action: 'subsciption' }))}
          />
        }
      </div>
    </div>
  )
}

export default Actions;
