import React, { useState, useEffect } from 'react';
import { firstEstimResult } from '../../../../shared/assets/images';
import GeocoderInput from '../../../../shared/components/GeocoderInput';
import { ga4Event } from '../../../../shared/utils/ga4Event';

const EstimationBanner = ({ gtmEvent, userId }) => {
  const [place, setPlace] = useState({});
  const [params, setParams] = useState(new URLSearchParams());

  useEffect(() => {
    if (place.lat && place.lng) handleSubmit();
  }, [place]);

  const handleSubmit = () => {
    if (gtmEvent) ga4Event(gtmEvent, { user_id: userId })

    if (!place.formatted_address || !place.lat || !place.lng) window.open(`${window.location.origin}/estimation`, '_blank');
    else {
      dataToParams()
      const url = `${window.location.origin}/estimation?${params.toString()}`;
      window.open(url, '_blank');
    }
    setPlace({});
  }

  const dataToParams = () => {
    setParams(new URLSearchParams());
    params.append('formatted_address', place.formatted_address);
    params.append('lat', place.lat);
    params.append('lng', place.lng);
    params.append('street_number', place.street_number);
    params.append('street_name', place.street_name);
    params.append('zip_code', place.zip_code);
    params.append('city', place.city);
    params.append('country_code', place.country_code);
  }

  return (
    <div className='relative flex justify-center items-center gap-5 md:gap-10 p-5 md:p-7 border-2 rounded-3xl border-ih-blue w-fit bg-hosman-gradient-1 cursor-pointer' onClick={() => handleSubmit()}>
      <div className='flex justify-center items-center flex-col gap-7'>
        <div className='flex flex-col gap-2'>
          <p className='text-xl font-bold text-ih-indigo text-center md:text-left'>Vous avez un potentiel projet de vente ?</p>
          <p className='text-ih-indigo-60 text-center md:text-left'>Obtenez une première estimation de votre bien, 100% gratuite !</p>
        </div>

        <div className='relative w-full text-ih-indigo' onClick={(e) => e.stopPropagation()}>
          <GeocoderInput
            name='formatted_address'
            label=''
            onChange={setPlace}
            value={place.formatted_address || ''}
            placeholder='Adresse du bien à estimer...'
            listPlacement='top-16'
            locationIconColor='text-ih-blue'
            handleSubmit={handleSubmit}
            withSubmitBtn={true}
            height='h-36 md:h-16'
          />
        </div>
      </div>

      <div className='hidden md:flex justify-center items-end md:h-36 md:w-44 bg-ih-blue rounded-t-full'>
        <img src={firstEstimResult} alt='Estimation' className='w-28 h-40'  />
      </div>
    </div>
  );
};

export default EstimationBanner;
