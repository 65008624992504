import React, { useState, useEffect } from 'react';

import PropertyCard from './components/PropertyCard';

import { LoadingLayer, FadeIn, Slider, Label } from 'hosman-material';

import BUYER_ALERTS from '../../../../../../../../api/ressources/App/buyerAlerts';

const CorrespondingProperties = ({ buyerAlertId }) => {
  const [properties, setProperties] = useState([])

  const fetchCorrespondingProperties = async () => {
    const response = await BUYER_ALERTS.getCorrespondingProperties(buyerAlertId)
    setProperties(response.data.results)
  }

  useEffect(() => {
    fetchCorrespondingProperties()
  }, []);

  const displayZipCode = (property) => {
    if (!['Paris', 'Marseille', 'Lyon'].includes(property.city)) return null;

    return property.zip_code
  }

  const setTitle = () => {
    return <p className='h2'>Ces biens correspondent à vos <span className='text-ih-blue'>critères de recherche</span></p>
  }

  if (properties.length < 1) return null;

  return (
    <div className='ih-container'>
      <LoadingLayer isReady={properties.length > 0}>
        <FadeIn>
          <Slider
            items={properties.map(property =>
              <div key={property.id} className='w-[270px]'>
                <PropertyCard
                  property={property}
                  centerLabel={<Label color='bg-ih-indigo'>{property.city} {displayZipCode(property)}</Label>}
                  price={property.sale.price}
                />
              </div>
            )}
            title={setTitle()}
          />
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default CorrespondingProperties;
