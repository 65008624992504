import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import usePreventRefresh from '../../../../../../../shared/utils/hooks/usePreventRefresh';
import { toast } from 'react-hot-toast';
import { notifySlack } from '../../../../../../../shared/utils/slackNotifier';

import Circle from './components/Circle';
import FadeInList from './components/FadeInList';

import { FadeIn } from 'hosman-material';

import ESTIMATIONS from '../../../../../../../shared/apiPublic/ressources/estimations';
import { purgeEstimationData, updateStep, updateEstimationData } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';
import { ga4Event } from '../../../../../../../shared/utils/ga4Event';

const Loader = () => {
  const { estimationData } = useSelector((state) => state.estimationFunnel);
  const [resultData, setResultData] = useState()
  const [isReady, setIsReady] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRefreshAttempted = usePreventRefresh();

  const createEstimation = async () => {
    const mergedObj = Object.assign(
      {},
      estimationData.placeData,
      estimationData.mainInfos,
      estimationData.extraInfos,
      estimationData.lead,
      { kind: estimationData.typology },
      { waiting_time_period: estimationData.waitingTimePeriod },
      { non_selling_reason: estimationData.nonSellingReason }
    );

    let response;
    try {
      if (localStorage.getItem('hasEffectRun') || mergedObj.email === undefined) return;

      localStorage.setItem('hasEffectRun', 'true');
      response = await ESTIMATIONS.createEstimation(mergedObj)
      setResultData(response.data)
      try {
        handleGaEvent(response.data);
      } catch (gaError) {
        console.error('Error in handleGaEvent:', gaError);
      }
    } catch (e) {
      const errorMessage = e.response?.data?.error?.message?.error || 'Une erreur est survenue lors de la création de votre estimation';
      toast.error(errorMessage, { id: 'estimation-creation-fail', duration: 3000 })
      dispatch(updateStep({ stepNumber: 1 }))
    }
  }

  useEffect(() => {
    const hasEffectRun = localStorage.getItem('hasEffectRun');

    // Make sure to only create estimation once
    if (!hasEffectRun) {
      createEstimation();
    }
  }, []);

  const handleGaEvent = (responseData) => {
    const payload = {
      city: responseData.property?.city,
      region: responseData.region?.name_raw,
      amount: responseData.last_yanport_valuation?.sale_price,
      potential_premium: responseData.potential_premium,
      estimation_reason: estimationData.estimationReason,
      property_kind: estimationData.typology,
      surface: estimationData.mainInfos.carrez_area || estimationData.mainInfos.living_area,
      user_id: responseData.owner?.id,
      user_email: estimationData.lead.email,
      user_phone: estimationData.lead.phone_number,
      user_first_name: estimationData.lead.first_name,
      user_last_name: estimationData.lead.last_name
    }

    ga4Event('complete_estimation', payload);
  }

  useEffect(() => {
    if (!isReady || !resultData) return;

    localStorage.removeItem('hasEffectRun');
    navigate(`/estimation/resultat/${resultData.unique_hash}`);
  }, [isReady, resultData]);

  return (
    <FadeIn>
      <div className='flex flex-col gap-10 md:w-3/5 mx-auto' data-testid='Loader'>
        <Circle resultData={resultData} setIsReady={setIsReady} />

        <p className='h2 text-ih-indigo text-center'>Première estimation de votre bien en cours...</p>

        <FadeInList />
      </div>
    </FadeIn>
  )
}

export default Loader;
