import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Modal, LoadingCircle } from 'hosman-material'
const isProd = window.location.host == ('app.hosman.co')
let BASE_URL = `${window.location.origin.replace('app.', '')}/api/v1`;
if (isProd) BASE_URL = BASE_URL.replace('hosman.co', 'www.hosman.co')

const DownloadImagesModal = ({ property, setDownloadImagesModalOpen }) => {
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      fetchZipFile();
      hasFetched.current = true;
    }
  }, []);

  const fetchZipFile = async () => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${property.id}/property_pictures`, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'X-Auth-Token': window.localStorage.getItem('token'),
          'Content-Type': 'application/zip',
        }
      });

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `photos-${property.street_name}.zip`);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setDownloadImagesModalOpen(false)
    } catch (error) {
      console.error('Erreur lors du téléchargement des images :', error);
    }
  }

  return (
    <Modal isOpen closeModal={() => setDownloadImagesModalOpen(false)}>
      <div className='flex flex-col gap-3 items-center p-6 w-full mx-auto mt-6 text-ih-indigo'>
        <LoadingCircle margin='m-0' size='w-6 h-6' />
        <p>Nous préparons vos photos... Cela peut prendre quelques secondes</p>
        <p>Votre téléchargement va bientôt commencer</p>
      </div>
    </Modal>
  )
}

export default DownloadImagesModal;

DownloadImagesModal.propTypes = {
  property: PropTypes.object.isRequired,
  setDownloadImagesModalOpen: PropTypes.func
};
