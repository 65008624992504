import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Characteristics from '../../../../../../../../shared/components/Property/Characteristics';
import { Card, Label, Avatar, Carousel } from 'hosman-material';
import setStateInfos from '../../utils/setStateInfos';

const SaleCard = ({ sale }) => {
  const property = sale.property
  const { allVisits, visitsState } = useSelector((state) => state.buyerVisits);
  const { allOffers, offersState } = useSelector((state) => state.buyerOffers);
  const [visits, setVisits] = useState([])
  const [offers, setOffers] = useState([])
  const [labelInfos, setLabelInfos] = useState()

  useEffect(() => {
    if (visitsState === 'done') setVisits(allVisits.filter(visit => visit.sale_id == sale.id));
    if (offersState === 'done') setOffers(allOffers.filter(offer => offer.sale_id == sale.id));
  }, [allVisits, allOffers]);

  useEffect(() => {
    setLabelInfos(setStateInfos(sale, visits, offers))
  }, [visits, offers]);

  if (!labelInfos) return null;

  return (
    <div className='group md:w-80 mx-auto' data-testid='SaleCard'>
      <Card>
        <div>
          <Carousel images={[property.property_pictures[0]?.photo_url]} />

          <div className='absolute top-4 z-10 left-4 flex gap-1'>
            <div className='flex flex-wrap gap-3'>
              <Label color={labelInfos.color}>{labelInfos.label}</Label>
            </div>
          </div>
        </div>

        <div className='relative p-6'>
          {sale.expert_picture_url &&
            <div className='absolute -top-6 z-10'>
              <Avatar border imageSrc={sale.expert_picture_url} />
            </div>
          }

          <div className='absolute -top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <Label color='bg-ih-indigo'>{property.city}</Label>
          </div>

          <p className={'text-sm font-semibold truncate my-4 text-ih-neutral'}>
            {property.street_number} {property.street_name} {property.zip_code} {property.city}
          </p>

          <Characteristics property={property} short />
        </div>

        <div className='h4 text-center text-ih-blue-5 bg-ih-blue p-3 rounded-b-2xl transition-all w-full group-hover:bg-ih-blue-80'>
          Accéder au bien
        </div>
      </Card>
    </div>
  )
}

export default SaleCard;

// PropTypes
SaleCard.propTypes = {
  sale: PropTypes.object.isRequired
};
