import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { starStruck } from '../../../shared/assets/emojis';
import { ga4Event } from '../../../shared/utils/ga4Event';

import { MenuItem, PropertySelect, Emoji } from 'hosman-material';

const MobileMenu = ({
  sidebarOpen,
  setSidebarOpen,
  handleColor,
  handleClick,
  navigation,
  clientSwitch
}) => {
  const { user } = useSelector((state) => state.user);
  const { sale, sales, propertySelectOptions } = useSelector((state) => state.sellerSales);
  let navigate = useNavigate();

  const handleInnerClick = (item) => {
    handleClick(item);
    item.gtmEventParams && ga4Event(item.gtmEventParams, { user_id: user.id });
  }

  const handleSaleChange = (eventSale) => {
    setSidebarOpen(false);
    navigate(`/ev/ventes/${eventSale.id}`);
  }

  const handleTitle = (item) => {
    if (item.subtitle) {
      return(
        <>
          <p>{item.name}</p>

          <div className='flex items-center gap-1 text-sm font-normal text-ih-blue'>
            {item.subtitle}
            <Emoji emojiSrc={starStruck} width='w-5' />
          </div>
        </>
      )
    } else return item.name
  }

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative lg:hidden'
        onClose={() => setSidebarOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter='transition-all ease-linear duration-200'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-all ease-linear duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-white top-16' />
        </Transition.Child>

        <div className='fixed inset-0 flex top-16'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-200 transform'
            enterFrom='-translate-y-full'
            enterTo='translate-y-0'
            leave='transition ease-in-out duration-200 transform'
            leaveFrom='translate-y-0'
            leaveTo='-translate-y-full'
          >
            <Dialog.Panel className='relative flex-1 flex flex-col w-full bg-hosman-gradient-1 '>
              <div className='h-full flex flex-col justify-between p-5 overflow-y-auto'>
                <div className='mx-auto'>
                  {clientSwitch && clientSwitch()}
                </div>
                {sales.length > 1 && (
                  <div className='mt-5'>
                    {sale.id &&
                      <PropertySelect
                        selectedOption={{
                          address: `${sale.property.street_number} ${sale.property.street_name} ${sale.property.zip_code} ${sale.property.city}`,
                        }}
                        options={propertySelectOptions}
                        handleChange={(sale) => handleSaleChange(sale)}
                      />
                    }
                  </div>
                )}

                <nav className='pt-10 grid gap-6 content-center'>
                  {navigation.map((item) => (
                    <div
                      key={item.name}
                      onClick={() => handleInnerClick(item)}
                      className={`cursor-pointer group flex items-center text-base font-medium rounded-2xl ${handleColor(
                        item
                      )}`}
                    >
                      <MenuItem
                        icon={item.icon}
                        title={handleTitle(item)}
                        iconColor={handleColor(item)}
                        textColor={handleColor(item)}
                        count={item.count}
                        disabled={item.disabled}
                      />
                    </div>
                  ))}
                </nav>
                <div className='h-52'></div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileMenu;

// PropTypes
MobileMenu.propTypes = {
  navigation: PropTypes.array,
  handleClick: PropTypes.func,
  handleColor: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func,
  topMenu: PropTypes.node,
  clientSwitch: PropTypes.func
};
