import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CONTACTS from '../../../../../../../api/ressources/BuyerApp/contacts';

import { FadeIn, ContactCard, InfoBox } from 'hosman-material';

const Contacts = ({ sale, visits, offers }) => {
  const { offersState } = useSelector(state => state.buyerOffers);
  const { visitsState } = useSelector(state => state.buyerVisits);
  const [contacts, setContacts] = useState([]);
  if (offers.length < 1 && visits.length < 1) return null;

  const fetchContacts = async () => {
    const params = {};
    if (offers.length > 0) {
      params.offer_id = offers[offers.length - 1].id;
    }

    if (visits.length > 0) {
      params.visit_id = visits[visits.length - 1].id;
    }

    const response = await CONTACTS.getContacts(params);
    setContacts(response.data);
  }

  useEffect(() => {
    if ([offersState, visitsState].some(state => state !== 'done')) return
    if (offers.length < 1 && visits.length < 1) return

    fetchContacts()
  }, [offersState, visitsState]);

  return (
    <FadeIn>
      <div className='flex flex-col gap-3'>
        <div className='w-full mt-10'>
          <div className='flex flex-col md:flex-row md:flex-wrap items-center md:items-start gap-4'>
            {contacts.map((contact) => {
              return (
                <div key={contact.id}>
                  <div className='rounded-3xl mb-5'>
                    <ContactCard
                      avatar={contact.profile_pic_url}
                      email={contact.type !== 'visit_guide' ? contact.email : ''}
                      name={`${contact.first_name} ${contact.last_name}`}
                      phone={contact.phone_number.replace('+33', '0')}
                      title={contact.type}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className='w-fit'>
          <InfoBox avatarSrc={sale.expert.photo}>
            {sale.expert.civility == 'mrs' && `${sale.expert.first_name} est votre interlocutrice principale sur ce bien, n'hésitez pas à la contacter pour toutes demandes d'informations`}
            {sale.expert.civility !== 'mrs' && `${sale.expert.first_name} est votre interlocuteur principal sur ce bien, n'hésitez pas à le contacter pour toutes demandes d'informations`}
          </InfoBox>
        </div>
      </div>
    </FadeIn>
  )
}

export default Contacts;
