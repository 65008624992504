import client from '../../client';

const OFFERS = {
  getOffers(params) {
    return client.get(`/sellers/${client.customerId()}/offers`, params);
  },

  getOffer(offerId) {
    return client.get(`/sellers/${client.customerId()}/offers/${offerId}`);
  },
}

export default OFFERS;
