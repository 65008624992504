import client from '../../client';

const USER = {
  getUser() {
    return client.get(`/users/${client.customerId()}`)
  },

  updateUser(params) {
    return client.put('/user', { user: params })
  },
}

export default USER;
