import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import USER from '../../api/ressources/App/user';

export const getUser = createAsyncThunk(
  '/user',
  async () => {
    const response = await USER.getUser()
    return response.data
  }
);

const initialState = {
  user: {
    authState: 'pending',
    civility: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    phone_country_code: '',
    salesCount: '',
    salesInterestsCount: '',
    phisical_address: {}
  },
  loggedUserId: Number(localStorage.getItem('loggedUserId'))
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateAuthState: (state, { payload }) => {
      state.user = {
        ...state.user,
        authState: payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user = {
        ...payload,
        authState: 'done',
        salesCount: payload.sales_count,
        salesInterestsCount: payload.offers_as_owner_count + payload.visits_count + payload.buyer_alerts_count
      }
    })
  },
})

export const { updateAuthState } = userSlice.actions;

export default userSlice.reducer;
