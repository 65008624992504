import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateLink } from '../../../../../../../../../shared/utils/railsRoutes';
import { AnimatePresence, motion } from 'framer-motion';

import CriteriasStep from './components/CriteriasStep';
import ContactDetailsStep from './components/ContactDetailsStep';

import { LoadingLayer } from 'hosman-material';
import { S_CircleXmark } from 'hosman-material';

const RightPanel = () => {
  const { isSubmitting, showContactDetailsForm } = useSelector((state) => state.buyerAlertCreator);
  const rightPanelRef = useRef();

  return (
    <div className='relative h-full overflow-x-hidden' ref={rightPanelRef}>
      <LoadingLayer isReady={!isSubmitting}>
        <div className='flex justify-end p-4'>
          <FontAwesomeIcon
            size='2xl'
            icon={S_CircleXmark}
            className='cursor-pointer text-ih-indigo'
            onClick={() => window.location = generateLink(RailsRoutes.annonces_path())}
          />
        </div>

        <div className='px-6 pb-6 md:px-12'>
          <AnimatePresence mode='wait'>
            {showContactDetailsForm ? (
              <motion.div
                key='ContactDetailsStep'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <ContactDetailsStep rightPanelRef={rightPanelRef} />
              </motion.div>
            ) : (
              <motion.div
                key='CriteriasStep'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <CriteriasStep rightPanelRef={rightPanelRef} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </LoadingLayer>

      {isSubmitting &&
        <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center text-ih-indigo'>
          <div className='font-bold'>Veuillez patienter pendant que nous créons votre alerte</div>
        </div>
      }
    </div>
  );
};

export default RightPanel;
