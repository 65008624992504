import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateLink } from '../../../../shared/utils/railsRoutes';

import { S_AngleUp, S_AngleDown, S_UserGroup, S_RightFromBracket, S_ArrowLeft } from 'hosman-material';
import { Avatar, Card, Button } from 'hosman-material';

const AvatarMenu = () => {
  const { user, loggedUserId, user: { first_name, last_name } } = useSelector((state) => state.user);

  return (
    <Menu as='div' className='relative'>
      {({ open }) => (
        <>
          <Menu.Button className='flex items-center space-x-1'>
            <Avatar
              textColor='text-ih-indigo lg:text-white'
              bgColor='bg-hosman-gradient-1 lg:bg-none lg:bg-ih-indigo'
              userName={`${first_name} ${last_name}`}
            />

            <FontAwesomeIcon
              icon={open ? S_AngleUp : S_AngleDown}
              className='h-6 w-6 text-ih-blue'
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute w-max right-0 top-14 flex flex-col'>
              <Card>
                <div className='flex flex-col p-3'>
                  <Menu.Item as='div'>
                    <Link to='/mon-compte'>
                      <Button icon={S_UserGroup} iconColor='text-ih-blue' type='menu' label='Mon compte' fullWidth />
                    </Link>
                  </Menu.Item>
                  <Menu.Item as='div'>
                    <a href={generateLink(RailsRoutes.root_path())}>
                      <Button icon={S_ArrowLeft} iconColor='text-ih-blue' type='menu' label='Revenir à hosman.co' fullWidth />
                    </a>
                  </Menu.Item>
                  {user.id == loggedUserId &&
                    <Menu.Item as='div'>
                      <a href={generateLink(RailsRoutes.destroy_user_session_path())} data-method='delete' rel='nofollow'>
                        <Button icon={S_RightFromBracket} iconColor='text-ih-blue' type='menu' label='Déconnexion' fullWidth />
                      </a>
                    </Menu.Item>
                  }
                </div>
              </Card>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default AvatarMenu;

// PropTypes
AvatarMenu.propTypes = {
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func
};
