import React from 'react';
import { SelectInput } from 'hosman-material';

const outdoorSpaceOptions = [
  { id: 0, value: 'no_matter', name: 'Pas nécessairement' },
  { id: 1, value: 'everything', name: 'Oui, quel qu\'il soit' },
  { id: 2, value: 'garden', name: 'Jardin' },
  { id: 3, value: 'balcony', name: 'Balcon' },
  { id: 4, value: 'terrace', name: 'Terrasse' },
];

const OutdoorSpaceSelect = ({ buyerAlert, setStateBuyerAlert, errors }) => {
  return (
    <div className='min-w-[280px]'>
      <SelectInput
        name='outdoor_space'
        options={outdoorSpaceOptions}
        selectedOption={outdoorSpaceOptions.find(option => option.value === buyerAlert.outdoor_space)}
        handleChange={(e) => setStateBuyerAlert({...buyerAlert, outdoor_space: e.value})}
        label='Espace(s) extérieur(s)'
        errors={errors}
      />
    </div>
  )
}

export default OutdoorSpaceSelect;
