import client from '../../client';

const OFFERS = {
  getOffers(params) {
    return client.get(`/buyers/${client.customerId()}/offers`);
  },

  getOffer(offerId) {
    return client.get(`/buyers/${client.customerId()}/offers/${offerId}`);
  },
}

export default OFFERS;
