import React from 'react';
import PropTypes from 'prop-types';
import { partyingFace } from '../../../../../../../../../../shared/assets/emojis';
import { generateLink } from '../../../../../../../../../../shared/utils/railsRoutes';

import Characteristics from '../../../../../../../../../../shared/components/Property/Characteristics';
import { Card, Label, Avatar, Carousel, Emoji } from 'hosman-material';

const PropertyCard = ({ property }) => {
  return (
    <div className='w-60 relative mx-auto' data-testid='PropertyCard'>
      <a href={generateLink(RailsRoutes.adverts_property_path(property.slug))} target='_blank'>
        {property.sale.recently_published &&
          <div className='absolute top-5 left-5 z-10'>
            <Label color='bg-ih-green flex items-center gap-1'>Nouveau <Emoji emojiSrc={partyingFace} width='w-4' /></Label>
          </div>
        }

        {property.sale.premium &&
          <div className='absolute top-5 right-5 z-10'>
            <Label color='bg-ih-indigo premium-font'>Premium</Label>
          </div>
        }

        <Card classes='hover:bg-ih-blue-5 hover:opacity-90 transition-all'>
          <Carousel images={[property.picture_url]} />

          <div className='relative p-6'>
            {property.sale.expert?.photo &&
              <div className='absolute -top-6 z-10'>
                <Avatar border imageSrc={property.sale.expert.photo} />
              </div>
            }

            <div className='absolute -top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
              <Label color='bg-ih-indigo'>{property.city}</Label>
            </div>

            <p className={'text-sm font-semibold truncate my-4 text-ih-neutral'}>
              {property.street_number} {property.street_name} {property.zip_code} {property.city}
            </p>

            <Characteristics property={property} short />
          </div>

          <div className='h4 text-center text-ih-blue bg-ih-purple-10 p-3 rounded-b-2xl transition-all w-full'>
            {property.sale.price.toLocaleString()} €
          </div>
        </Card>
      </a>
    </div>
  )
}

export default PropertyCard;

// PropTypes
PropertyCard.propTypes = {
  property: PropTypes.object.isRequired
};
