import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildingDrawing, houseDrawing } from '../../../../../../../shared/assets/emojis';
const classNames = require('classnames');
import { initialExtraInfos } from '../../../../../../reducers/_Pages/Public/initialData';

import NavigationButtons from '../../components/NavigationButtons'
import { FadeIn } from 'hosman-material';
import { S_Check, D_CircleExclamation } from 'hosman-material';

import { updateStep, updateEstimationData } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const Typology = () => {
  const { estimationData, estimationData: { typology }, errors} = useSelector((state) => state.estimationFunnel);
  const dispatch = useDispatch();

  const kinds = [
    { slug: 'apartment', label: 'Un appartement', icon: buildingDrawing },
    { slug: 'house', label: 'Une maison', icon: houseDrawing }
  ]

  const typologyIs = (kind) => {
    return kind === typology;
  }

  const cardClasses = (kind) => {
    return classNames(
      'relative flex flex-col justify-around rounded-2xl p-3 h-32 md:h-52 w-56 bg-white cursor-pointer',
      { 'border border-ih-blue-30 hover:border-ih-blue': !typologyIs(kind) },
      { 'border-2 border-ih-blue': typologyIs(kind) }
    )
  }

  const handleChange = (kind) => {
    dispatch(updateEstimationData({ ...estimationData, typology: kind, extraInfos: initialExtraInfos }))
    dispatch(updateStep({ stepNumber: 3 }))
  }

  return (
    <div data-testid='Typology'>
      <FadeIn>
        <div className='flex flex-col gap-6'>
          <p className='h2 text-ih-indigo'>Quel type de bien souhaitez-vous estimer ?</p>

          <div className='flex gap-3 md:gap-8'>
            {kinds.map(kind => {
              return (
                <div key={kind.slug} onClick={() => handleChange(kind.slug)} className={cardClasses(kind.slug)}>
                  {typologyIs(kind.slug) &&
                    <div className='absolute top-2 right-2'>
                      <FontAwesomeIcon icon={S_Check} className='flex items-center justify-center p-1 rounded-md bg-ih-green text-white' />
                    </div>
                  }
                  <div className='flex flex-col gap-3 text-center'>
                    <img src={kind.icon} className='mx-auto w-26 md:w-32' />
                    <p className='h4 text-ih-indigo'>{kind.label}</p>
                  </div>
                </div>
              )
            })}
          </div>

          {errors.typology &&
            <div className='flex justify-start items-center gap-2'>
              <FontAwesomeIcon icon={D_CircleExclamation} size='lg' className='text-ih-red' />

              <p className='text-ih-red-60 text-xs'>{errors.typology}</p>
            </div>
          }

          <NavigationButtons classes='hidden md:block my-5' />
        </div>
      </FadeIn>
    </div>
  )
}

export default Typology;
