import React from 'react';
import { generateLink } from '../../../../../../shared/utils/railsRoutes';

import { Button } from 'hosman-material';
import { ga4Event } from '../../../../../../shared/utils/ga4Event';
import { propertyCardCarousel } from '../../../../../../shared/assets/images';

const PropertyAdverts = ({ sale: { owner, potential_premium } }) => {
  const handleGa4Event = () => {
    const data = {
      potential_premium,
      user_id: owner.id,
      user_email: owner.email,
      user_first_name: owner.first_name,
      user_last_name: owner.last_name
    }

    ga4Event('click_adverts_success_page', data);
  }

  return (
    <a href={generateLink(RailsRoutes.annonces_path())} target='_blank' onClick={handleGa4Event}>
      <div className='flex flex-col gap-3 text-ih-indigo border-2 border-ih-purple-20 hover:border-ih-purple-40 bg-hosman-gradient-1 rounded-3xl p-5' data-testid='PropertyAdverts'>
        <p className='h2 text-ih-indigo text-center'>Un projet d'achat ?</p>

        <img src={propertyCardCarousel} className='w-60 mx-auto' />

        <div className='md:w-max mx-auto'>
          <Button label='Découvrez nos biens en vente' />
        </div>
      </div>
    </a>
  );
}

export default PropertyAdverts;
