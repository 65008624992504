import React from 'react';

import PageTitle from '../../../../components/PageTitle/PageTitle';
import SponsorshipCard from '../../../../components/Marketing/SponsorshipCard'

import { D_MessageHeart } from 'hosman-material';
import { FadeIn } from 'hosman-material';

const Sponsorship = () => {
  return (
    <div data-testid='Sponsorship'>
      <PageTitle icon={D_MessageHeart} title='Parrainez un proche' />

      <FadeIn>
        <div className='md:mx-8 md:mt-24'>
          <SponsorshipCard />
        </div>
      </FadeIn>
    </div>
  )
};

export default Sponsorship;
