import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { folder } from '../../../../../../../../shared/assets/emojis';

import { getDocuments, fileEdit, addInput, addPropertyKindGroup, updateInputs, handleDocChanges } from '../../../../../../../reducers/SellerApp/documentsSlice'
import { addDocument, getDocumentTypes, deleteDocument } from '../../../../../../../reducers/SellerApp/documentsSlice';

import { Emoji, DocumentsBox, ProgressCircle, FadeIn } from 'hosman-material';
import { FileInputContainer } from './lib/FileInputContainer';
import { FileInput } from './lib/FileInput';
import { FileInputUploaded } from './lib/FileInputUploaded';
import { SelectInputFile } from './lib/SelectInputFile';

const DocumentsTab = () => {
  const dispatch = useDispatch()
  const { saleId } = useParams();

  const { groupedDocumentTypes, addedInputs, docTypeInfos, mandatoryDocs, uploadedOptionalDocs, allDocs, isLoading, lastDocumentUpload } = useSelector((state) => state.sellerDocuments);
  const { user } = useSelector((state) => state.user);
  const { sale } = useSelector((state) => state.sellerSales);
  const [uploadProgression, setUploadProgression] = useState(0);
  const [propertyKindGroup, setPropertyKindGroup] = useState('');

  useEffect(() => {
    if (!sale.id) return;
    const kindGroup = handlePropertyKindGroup(sale.property.kind)

    setPropertyKindGroup(kindGroup)
    dispatch(getDocuments(saleId));
    dispatch(getDocumentTypes(kindGroup));
  }, [sale])

  useEffect(() => {
    if (Object.keys(groupedDocumentTypes).length === 0) return;

    dispatch(handleDocChanges(propertyKindGroup))
  }, [groupedDocumentTypes])

  useEffect(() => {
    if (!sale.id || mandatoryDocs.length === 0) return;

    const mandatoryDocsLength = mandatoryDocs.length
    const mandatoryDocsNotUploadedLength = mandatoryDocs.filter(doc => doc.documents.length > 0).length

    setUploadProgression((mandatoryDocsNotUploadedLength / mandatoryDocsLength) * 100)
  }, [mandatoryDocs])

  const handlePropertyKindGroup = (propertyKind) => {
    let group = null
    const apartmentGroup = ['apartment', 'duplex', 'triplex', 'loft']
    const houseGroup = ['house', 'mansion']
    const otherGroups = ['parking']

    if (apartmentGroup.includes(propertyKind)) group = 'apartment'
    if (houseGroup.includes(propertyKind)) group = 'house'

    return group || 'other'
  }

  const handleOptionChange = ({id}) => {
    dispatch(addInput(id));
  }

  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDocUpload = async (file, dataFile) => {
    dispatch(fileEdit(dataFile.id))
    // Create form for file
    const form = new FormData();
    form.append('file', file);
    form.append('document_type_id', dataFile.id);
    await dispatch(addDocument({saleId, form}));
    await dispatch(updateInputs({...dataFile}));
  }

  // Triggers when file is dropped
  const handleDrop = async(e, dataFile, categorySlug) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files
    const file = e.dataTransfer.files[0]
    if (file && files) {
      handleDocUpload(file, {...dataFile, categorySlug});
    }
  };

  // triggers when file is selected with click
  const handleFileInput = async (e, dataFile, categorySlug) => {
    const files = e.currentTarget.files
    const uploadedFile = e.currentTarget.files[0]
    if (files && uploadedFile) {
      handleDocUpload(uploadedFile, {...dataFile, categorySlug});
    }
  }

  const documentCanBeDeleted = (document) => {
    return document.uploader_id === user.id && sale.state === 'awaiting_publication'
  }

  return (
    <FadeIn>
      <div className='flex flex-col items-center w-fill md:mx-24' data-testid='DocumentsTab'>
        <div className='h2 text-ih-indigo flex justify-around my-10'>
          <div className='flex flex-col md:flex-row items-center gap-4'>
            <Emoji emojiSrc={folder} width='w-8' />
            <p>Vos documents de vente</p>
            {propertyKindGroup !== 'other' && <ProgressCircle value={uploadProgression} />}
          </div>
        </div>

        {Object.entries(groupedDocumentTypes).map(([categorySlug], index) => (
          <div key={`${categorySlug}-${index}`} className='w-full'>
            <DocumentsBox emoji={docTypeInfos[categorySlug].emoji} title={docTypeInfos[categorySlug].name} >
              <div className='md:p-5 grid grid-cols-1 md:grid-cols-2 gap-8'>
                {mandatoryDocs.map((doc, index) => (
                  doc.category_slug === categorySlug &&
                  <FileInputContainer
                    key={`${doc.id}-${index}`}
                    file={doc}
                    uploadedLabel={doc.documents.length > 0}
                    mandatoryLabel={doc[`mandatory_${sale?.property?.kind}`] === true}>
                    {doc.documents.length > 0 ?
                      doc.documents.map((docUploaded, index) => (
                        <FileInputUploaded
                          key={`${docUploaded.id}-${index}`}
                          file={docUploaded}
                          isLoading={isLoading}
                          lastDocumentUpload={lastDocumentUpload}
                          deleteAction={documentCanBeDeleted(docUploaded) && (() => dispatch(deleteDocument(docUploaded.id)))}
                        />
                      ))
                    :
                      [
                        <FileInput
                          key={`${doc.id}-${index}`}
                          file={doc}
                          categorySlug={categorySlug}
                          handleDrag={handleDrag}
                          handleDrop={handleDrop}
                          handleFileInput={handleFileInput}
                          isLoading={isLoading}
                          lastDocumentUpload={lastDocumentUpload} />
                      ]
                    }
                  </FileInputContainer>
                ))}

                {uploadedOptionalDocs.map((doc, index) => (
                  doc.category_slug === categorySlug &&
                  <FileInputContainer
                    key={`${doc.id}-${index}`}
                    file={doc}
                    uploadedLabel={true}>
                      {doc.documents.map((docUploaded, index) => (
                        <FileInputUploaded
                          key={`${docUploaded.id}-${index}`}
                          file={docUploaded}
                          isLoading={isLoading}
                          lastDocumentUpload={lastDocumentUpload}
                          deleteAction={documentCanBeDeleted(docUploaded) && (() => dispatch(deleteDocument(docUploaded.id)))}
                        />
                      ))}
                  </FileInputContainer>
                ))}

                {Object.values(addedInputs[categorySlug]).length > 0 && Object.values(addedInputs[categorySlug]).map((input, index) => (
                  <FileInputContainer
                    key={`${input.id}-${index}`}
                    file={input}>
                      {[
                        <FileInput
                          key={`${input.id}-${index}-file`}
                          file={input}
                          categorySlug={categorySlug}
                          handleDrag={handleDrag}
                          handleDrop={handleDrop}
                          handleFileInput={handleFileInput}
                          isLoading={isLoading}
                          lastDocumentUpload={lastDocumentUpload}
                        />

                      ]}
                  </FileInputContainer>
                ))}

                {allDocs.length > 1 &&
                  <SelectInputFile optionList={allDocs} categorySlug={categorySlug} handleChange={handleOptionChange} />
                }
              </div>
            </DocumentsBox>
          </div>
        ))}
      </div>
    </FadeIn>
  )
}

export default DocumentsTab;
