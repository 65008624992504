import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Avatar, Box } from 'hosman-material';
import { S_Phone, S_Envelope } from 'hosman-material';

const ContactCard = ({ expert }) => {
  return (
    <div className='bg-ih-purple-10 w-fit px-2 py-4 h-full flex flex-col md:flex-row items-center gap-3 border border-ih-blue rounded-3xl text-center md:text-left' data-testid='ContactCard'>
      <Avatar imageSrc={expert.photo} border={true} />

      <div>
        <p className='text-sm'>Expert Hosman</p>

        <p className='text-sm flex flex-col md:flex-row gap-1'>
          <span className='font-semibold'>{expert.first_name}</span>
          <span>({expert.phone_number.replace(/.{2}/g, '$& ').trim()})</span>
        </p>
      </div>

      <div className='flex'>
        <a href={`tel:${expert.phone_number}`} className='mr-3'>
          <Box bgColor='bg-ih-purple-20 hover:bg-ih-purple-30'>
            <FontAwesomeIcon icon={S_Phone} data-testid='icon-box' className='text-ih-indigo' />
          </Box>
        </a>

        <a href={`mailto:${expert.email}`}>
          <Box bgColor='bg-ih-blue-20 hover:bg-ih-blue-30'>
            <FontAwesomeIcon icon={S_Envelope} data-testid='icon-box' className='text-ih-blue' />
          </Box>
        </a>
      </div>
    </div>
  );
};

export default ContactCard;

ContactCard.propTypes = {
  expert: PropTypes.object.isRequired,
};
