import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateLink } from '../../../../../../../../shared/utils/railsRoutes';

import { Card, Box, Button } from 'hosman-material';
import { D_CalendarStar } from 'hosman-material';

const NoSalesInterestsCard = () => {
  return (
    <div className='md:w-3/4 mx-auto' data-testid='NoSalesInterestsCard'>
      <Card data-testid='NoSalesInterestsCard'>
        <div className='flex flex-col text-center gap-6 p-4 md:p-12'>
          <div className='mx-auto'>
            <Box><FontAwesomeIcon icon={D_CalendarStar} size='xl' className='text-ih-blue' /></Box>
          </div>

          <p className='h2 text-ih-indigo'>Vous n'avez pas encore <span className='text-ih-blue'>de visite prévue</span></p>

          <a href={generateLink(RailsRoutes.annonces_path())} target='_blank'>
            <Button label='Découvrir nos biens en vente' />
          </a>
        </div>
      </Card>
    </div>
  )
}

export default NoSalesInterestsCard;
