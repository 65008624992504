import axios from 'axios';
const querystring = require('querystring');

const isProd = window.location.host == ('app.hosman.co')
let BASE_URL = `${window.location.origin.replace('app.', '')}/api/v1`;
if (isProd) BASE_URL = BASE_URL.replace('hosman.co', 'www.hosman.co')

const config = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Auth-Token': window.localStorage.getItem('token')
  }
};

const client = {
  customerId() {
    return window.localStorage.getItem('customerId')
  },

  get(path, params) {
    return axios.get(`${BASE_URL}${path}?${querystring.stringify(params)}`, config)
  },

  post(path, params) {
    return axios.post(`${BASE_URL}${path}`, params, config)
  },

  delete(path) {
    return axios.delete(`${BASE_URL}${path}`, config)
  },

  put(path, params) {
    return axios.put(`${BASE_URL}${path}`, params, config)
  },
}

export default client;
