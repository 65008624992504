import React from 'react';
import { SelectInput } from 'hosman-material';

const floorOptions = [
  { id: 1, value: 'no_matter', name: 'Peu importe' },
  { id: 2, value: 'only_ground', name: 'RDC uniquement' },
  { id: 3, value: 'no_ground', name: 'Pas de RDC' },
  { id: 4, value: 'high_floor', name: 'Etages élevés uniquement' },
];

const FloorSelect = ({ buyerAlert, setStateBuyerAlert }) => {
  return (
    <SelectInput
      name='floor'
      options={floorOptions}
      selectedOption={floorOptions.find((option) => option.value === buyerAlert.floor) || floorOptions[0]}
      handleChange={(e) => setStateBuyerAlert({ ...buyerAlert, floor: e.value })}
      label={<p>Étage <span className='text-xs font-normal'>(appartement uniquement)</span></p>}
    />
  );
};

export default FloorSelect;
